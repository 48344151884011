import React from "react";
import Banner from "../assets/images/banner-qa.jpeg";
import image1 from "../assets/images/qa-1-1.jpg";
import image2 from "../assets/images/qa-2.jpg";
import image3 from "../assets/images/qa-3.jpg";
import image4 from "../assets/images/qa-4.jpg";
import ProjectContentItem from "../components/project-content-item/ProjectContentItem";

const listContentTimeline = [
  {
    title: "// Software testing strategy",
    name: "Functional testing types",
    description: (
      <div className="flex flex-col gap-4">
        <div>
          Once we have this information, we’ll begin a dialogue with you that
          will get us started.
        </div>
        <div>
          "Testing can be broken down into three types: unit testing,
          integration testing, and functional testing. All of these types of
          testing look for ways to improve the code at different scales, from
          the individual components up."
        </div>
      </div>
    ),
    subContent: [
      {
        title: "Unit Testing",
        content: [
          "Unit testing is how a developer checks a module of code to make sure it’s ready to use. This testing ensures the code is working well in isolation, i.e. without integrating with other pieces of code. It’s like making sure each brick is sturdy enough to use before you put them together to make a wall. Unit testing has to come before integration testing, and can continue to happen throughout the development process.",
        ],
      },
      {
        title: "Integration Testing",
        content: [
          "A developer or team uses integration testing to make sure the separate modules of code are working correctly when combined together. If unit testing checks the bricks, integration testing checks the structural integrity of the wall. Each module has to work correctly with each other for the program to run right. Integration testing usually takes place once unit testing has been carried out and the team is ready to move to the next stage.",
        ],
      },
      {
        title: "Functional Testing",
        content: [
          "Functional tests are essentially the big brother of integration tests, and check the functionality of the software. This ensures that the program does what it’s actually intended to do — and that all the pieces are working together seamlessly.",
        ],
      },
    ],

    img: image1,
  },
  {
    title: "// AI Test",
    name: "Automated testing",
    description:
      "In the past, programmers had to slave away for hours over thousands of lines of spaghetti code to find a bug. These days, however, automated testing has become a developer’s best friend, and can save both programmer and client huge amounts of time and money.",

    img: image2,
    subContent: [
      {
        title: "Check out some key benefits of automated testing:",
        content: [
          "70% faster than manual testing — saves time and money",
          "Wider test coverage of application features",
          "Reliable, accurate, and efficient results",
          "Ensures consistency",
        ],
      },
    ],
    bonus: (
      <div className="flex flex-col gap-4">
        <div>
          At CfiDev, we automate the feature testing of hybrid, native, or
          mobile web applications with open-source frameworks such as Appium and
          Selenium
        </div>
        <div>
          On a lower level, our skills extend to the creation of unit testing
          for robust, continuous integration.
        </div>
        <div>
          Jenkins comes on top to verify the results of each unit test when
          generating the deliverable builds.
        </div>
      </div>
    ),
  },
  {
    title: "// Human Test",
    name: "Manual testing",
    description:
      "Our automated testing processes are very good at their jobs, but nothing can replace a human’s judgment. That’s why we rely on a combination of manual and automatic testing to ensure that our software is clean, tidy, and functional.",
    bonus:
      "Our QA team hunts down any potential bug and squashes it before it ever becomes an issue.",
    img: image3,
  },
  {
    title: "// Security First",
    name: "Security Audit",
    description: (
      <div className="flex flex-col gap-4">
        <div>
          "Security is one of any smart developer’s top concerns, and it’s
          certainly one of ours too. We’re CfiDev, after all. We’ll provide a
          full security audit of your software to make sure you and your data
          are safe from hackers and other potential security breaches."
        </div>
        <div>
          Even people outside of the tech world are aware of how vulnerable
          software can make them, so we offer full security audits for our
          clients to make sure you’re safe.
        </div>
      </div>
    ),

    img: image4,
  },
];

const QaTesting = () => {
  return (
    <div>
      <div
        className="object-cover w-full min-h-[610px] h-full pt-20 pb-10 lg:min-h-full"
        style={{
          backgroundImage: `url(${Banner})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="py-24 px-10 max-w-[1440px] mx-auto">
          <div className="flex w-[80%] lg:mx-auto flex-col gap-8 text-white md:w-full ">
            <h2 className="text-[60px] md:text-[32px] font-bold md:text-center">
              QA & TESTING SERVICES
            </h2>
            <span className="max-w-[850px] text-[18px] md:hidden">
              Testing is a crucial part of any software development. If a cook
              doesn’t taste their soup, how can they know it’s going to taste
              good? Our testers and QA experts dig beneath the surface and
              uproot potential problems before they start.
            </span>
            <span className="max-w-[850px] text-[18px] md:hidden">
              Testing is a crucial part of any software development. If a cook
              doesn’t taste their soup, how can they know it’s going to taste
              good? Our testers and QA experts dig beneath the surface and
              uproot potential problems before they start.
            </span>
          </div>
        </div>
      </div>
      <div className="max-w-[1440px] flex flex-col gap-32 md:gap-20 sm:gap-10 py-20 md:py-16 mx-auto xl:w-full px-10">
        {listContentTimeline.map((item, index) => (
          <ProjectContentItem item={item} key={index} index={index} />
        ))}
      </div>
    </div>
  );
};

export default QaTesting;
