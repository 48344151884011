import React from "react";
import thumbnail from "../../assets/images/thumbnail.jpg";

const GeneralInformation = () => {
  return (
    <div
      className="p-8 "
      style={{
        boxShadow: "0 .375em 2.8125em 0 #d2d5d9",
      }}
    >
      <div className="mb-5 text-[21px] text-black">General Information</div>
      <div className="flex flex-col gap-6">
        <div>
          <h2 className="pb-2 text-xl font-bold text-black">About us</h2>
          <ul className=" list-disc pl-8 leading-[30px]">
            <li>Full legal name: CfiDev LLC</li>
            <li>
              Address: CfiDev – 16BTT11, Van Quan, Ha Dong,
              Ha Noi, Vietnam
            </li>
            <li>
              {" "}
              Full legal name: CfiDev LLC Website: https://cfidevs.com
            </li>
            <li> Contact: intes_it@cfitech.vn – +84 97 851 0339</li>
            <li> Size: 150</li>
            <li> Funding: Private Company </li>
          </ul>
        </div>
       {/*  <div>
          <h2 className="pb-2 text-xl font-bold text-black">Brief history</h2>
          <div className="flex flex-col gap-5">
            <p>
              CfiDev was founded in 2017 by a team of Swiss entrepreneurs.
              CfiDev’s initial goal was to focus on fintech services,
              developing custom solutions for companies in the industry. In
              2016, CfiDev landed a contract with a major credit card issuer
              in Switzerland that kicked the company into high gear. The
              following year, in 2017, CfiDev began a contract with a start-up
              company owned by AirAsia.
            </p>
            <p>
              In 2020, the company changed hands to where it is now — a
              component of Verysell Group, which is also a Swiss company. This
              has led to significant growth of the company’s offshore
              development business and the release of new apps in fintech and
              many other sectors.
            </p>
            <p>
              In 2021, CfiDev is big enough to matter but small enough to
              care.
            </p>
          </div>
        </div> */}
        <div>
          <h2 className="pb-2 text-xl font-bold text-black">Why CfiDev?</h2>
          <div className="flex flex-col gap-5">
            <p>
              We have a proven track record of delivering squad-based
              recruitment for software-driven organisations across the UK,
              Europe, and offshore. Recent examples of building development
              squads include our work with Motorpoint, Resonate, Dunelm and
              ENSEK. Our commitment is to build deep, local, and technical
              communities of talent across the regions. We achieve this through
              a variety of methods, from headhunting to attending and running
              events — both online and in person. Our approach is proactive
              rather than reactive, as we actively engage our growing candidate
              communities and represent our client’s brand to attract the best
              technology candidates in the local area and beyond.
            </p>
            <p>
              Typically, going from request to on-site operation for an entire
              squad (6-strong development team) takes between 4-6 weeks.
            </p>
            <p>
              If you are wondering why Vietnam is your best bet for offshore
              software development, check out our neat infographic below:
            </p>
            <img src={thumbnail} alt="thumbnail" className="" />
            <div>
              <div>We have built core capabilities in:</div>
              <ul className=" list-disc pl-8 text-[18px] leading-[30px]">
                <li>Technology Leadership (Team Leads, Managers, Heads of)</li>
                <li>Architecture (Solution, Technical and Enterprise)</li>
                <li>JavaScript (including frameworks, e.g., React)7</li>
                <li>
                  Agile Delivery (Product, Business Analysis, Projects, and
                  Programmes, SCRUM, SOLID, TDD, BDD)
                </li>
                <li>Testing (Manual and Automation)</li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <h2 className="pb-2 text-xl font-bold text-black">Communication</h2>
          <ul className=" list-disc pl-8 leading-[30px]">
            <li>Communication</li>
            <li>Time zone:</li>
            <li>Onshore: BST</li>
            <li>Offshore: Indochina Time (BST +6)</li>
            <li>Onshore: Native language</li>
            <li>Offshore: Full Professional Proficiency</li>
            <li>Other foreign language skills:</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default GeneralInformation;
