import React from "react";

const ShareLayout = (props) => {
  const { headerImage, headerText, headerSubText, children } = props;
  return (
    <>
      <div
        style={{ backgroundImage: `url(${headerImage})` }}
        className="w-full bg-no-repeat bg-cover min-h-[500px] text-white pt-36 pb-8 pl-40 md:pl-20 bg-center md:min-h-full md:text-center"
      >
        <h1
          className="font-bold text-6xl uppercase  md:text-[32px]  "
          style={{ marginBottom: "2.75%", width: "70%" }}
        >
          {headerText}
        </h1>
        {headerSubText}
      </div>
      <div className="pt-20 2xl:max-w-[1440px] m-auto">{children}</div>
    </>
  );
};

export default ShareLayout;
