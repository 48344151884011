import React from "react";
import background from "../assets/images/banner-qa.jpeg";
import ContactForm from "../components/ContactForm";

const GetStarted = () => {
  return (
    <div>
      <div
        className="object-cover  w-full min-h-[610px] h-full pt-20 pb-10 lg:min-h-full"
        style={{
          backgroundImage: `url(${background})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className=" h-full mb-10 max-w-[1440px] mx-auto w-[80%]">
          <div className="my-40 lg:my-10">
            <h1 className="mb-10 text-6xl font-bold text-white min-sm:hidden ">
              LET’S TALK
            </h1>
            <h1 className="text-[34px] font-bold text-center	text-white mb-10 min-sm:block hidden">
              INSIGHTS
            </h1>
            <p className="text-[17.5px]	font-normal text-white mb-7 md:hidden">
              Just fill out the form below and include a description of your
              project. We typically respond within 2 business days to schedule a
              meeting.
            </p>
          </div>
        </div>
      </div>
      <ContactForm />
    </div>
  );
};
export default GetStarted;
