import React from "react";

const DemoApp = ({ appImage }) => {
  return (
    <div className={"h-fit w-full mt-18"}>
      <img src={appImage} alt={"demo app"} className={"object-cover"} />
    </div>
  );
};

export default DemoApp;
