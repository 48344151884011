import React, { useState } from "react";

import mobileApp from "../assets/images/mb-app.jpg";
import mobileAppHover from "../assets/images/mb-app-hover.jpg";
import webApp from "../assets/images/web-app.jpg";
import webAppHover from "../assets/images/web-app-hover.jpg";
import backEnd from "../assets/images/backend-1.jpg";
import backEndHover from "../assets/images/backend-hover.jpg";
import qa from "../assets/images/qa-1.jpg";
import qaHover from "../assets/images/qa-hover.jpg";

import backgroudTogether from "../assets/images/background-together.jpeg";

import ReactPlayer from "react-player";

import "../styles/index.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/swiper-bundle.css";
import { Link } from "react-router-dom";
import ContactForm from "../components/ContactForm";
import TechStack from "../components/commom/TechStack";
import { demoAppList } from "../utils/mock-data";
import DemoApp from "../components/DemoApp";

const Index = () => {
  const [isHovering, setIsHovering] = useState(Array(10).fill(false));

  const onMouseEnter = (index) => {
    setIsHovering((prev) => {
      const newHoverState = [...prev];
      newHoverState[index] = true;
      return newHoverState;
    });
  };

  const onMouseLeave = (index) => {
    setIsHovering((prev) => {
      const newHoverState = [...prev];
      newHoverState[index] = false;
      return newHoverState;
    });
  };
  return (
    <>
      <div
        style={{
          backgroundImage: `url(https://www.smartdev.com/wp-content/uploads/2021/10/banner-iot-service.jpg)`,
        }}
        className="bg-black opacity-0.3 w-full  h-fit bg-no-repeat bg-cover"
      >
        <div
          style={{
            fontSize: 24,
            color: "#000",
            textAlign: "center",
            zIndex: 500,
          }}
          className="pt-[180px] pb-[150px]"
        >
          <h1 className="text-5xl font-bold	text-white mb-2.5  flex-row flex justify-center">
            <p className="text-5xl">CFI</p>
            <p className="text-3xl mt-3 ">Tech</p>
          </h1>
          <h2 className="text-4xl	font-semibold text-white mb-3	">
            OUTCOME DRIVEN SOFTWARE
          </h2>

          <Link
            to={"get-started"}
            className="rounded-[50px] font-bold border-0 text-white py-4 px-10  text-[18px] bgi-gradion"
          >
            WORK WITH US TODAY
          </Link>
        </div>
      </div>
      <div className="h-fit w-full bg-white py-10 min-sm:min-h-[300vh]">
        <div className="py-8">
          <h4 className="text-[30px] font-bold  text-center">
            {"// What we do"}
          </h4>
          <h2 className="font-bold pb-[10px] text-[50px] text-center">
            Our Services
          </h2>
        </div>
        <>
          <div className="grid grid-cols-2 gap-[10%] px-[20%] min-sm:grid-cols-1">
            <div className="flex flex-col">
              <img
                src={isHovering[1] ? mobileAppHover : mobileApp}
                alt="mobile"
                width={"58%"}
                className="mb-4 mx-auto"
                onMouseEnter={() => onMouseEnter(1)}
                onMouseLeave={() => onMouseLeave(1)}
              />
              <h2 className="text-[#00b4ff] text-[25px] font-bold mb-4">
                Mobile App development
              </h2>
              <p className="text-[17.5px] font-medium ">
                Your mobile app deserves the A team — nothing less. Our Android
                and iOS app development experts have made apps for startups, big
                businesses, and everything in between.
              </p>
            </div>
            <div className="flex flex-col">
              <img
                src={isHovering[2] ? webAppHover : webApp}
                alt="web-app"
                width={"100%"}
                className="mb-4"
                onMouseEnter={() => onMouseEnter(2)}
                onMouseLeave={() => onMouseLeave(2)}
              />
              <h2 className="text-[#00b4ff] text-[25px] font-bold mb-4">
                Web App development
              </h2>
              <p className="text-[17.5px] font-medium ">
                Snappy, tailor-made web applications require cutting-edge tech —
                which we revel in. That translates to a sleek and secure app
                built in a time frame to meet your needs.
              </p>
            </div>
            <div className="flex flex-col">
              <img
                src={isHovering[3] ? backEndHover : backEnd}
                alt="backend"
                width={"56%"}
                className="mb-4 mx-auto"
                onMouseEnter={() => onMouseEnter(3)}
                onMouseLeave={() => onMouseLeave(3)}
              />
              <h2 className="text-[#00b4ff] text-[25px] font-bold mb-4">
                Back-end development
              </h2>
              <p className="text-[17.5px] font-medium ">
                While your customers or clients are busy being impressed by
                responsive user interfaces and clean front-end development, you
                can be confident knowing your app performs and scales how it
                needs to. Robust, well-conceived APIs mean your app can take all
                you throw at it — and then some.
              </p>
            </div>
            <div className="flex flex-col">
              <img
                src={isHovering[4] ? qaHover : qa}
                alt="qa-testing"
                width={"75%"}
                className="mb-4"
                onMouseEnter={() => onMouseEnter(4)}
                onMouseLeave={() => onMouseLeave(4)}
              />
              <h2 className="text-[#00b4ff] text-[25px] font-bold mb-4">
                QA & Testing
              </h2>
              <p className="text-[17.5px] font-medium ">
                We don’t wait for problems to sneak up on us — our
                search-and-destroy team of QA experts is on them before anything
                goes wrong. Our full-stack quality assurance and testing ensures
                clean features and an immaculate UI.
              </p>
            </div>
          </div>
        </>
      </div>
      <div className="h-fit w-full bg-white mt-[17rem] p-10 md:mt-4">
        <ContactForm />
      </div>
      <div className="h-fit w-full  p-20 md:mt-4">
        <TechStack />
      </div>
      <h1 className={"text-5xl font-bold text-center my-4"}>
        App we have build
      </h1>
      {demoAppList.map((item, index) => (
        <DemoApp appImage={item} key={index} />
      ))}

      <div className="h-fit w-full bg-black pt-28 pb-20 mt-10">
        <div className=" text-white w-[75%] mx-auto  flex flex-col ">
          <div className=" flex flex-row py-9 gap-9 min-sm:flex-col">
            <div className="w-1/2 min-sm:w-full">
              <h4 className="text-[30px] font-bold  ">{"// Why Us"}</h4>
              <h2 className="font-bold pb-[10px] text-[50px] mb-[30px]">
                Why CFIDev?
              </h2>
              <p className="text-[17.5] font-medium mb-5">
                You need excellent work. We provide it. It’s simple.
              </p>
              <p className="text-[17.5] font-medium mb-5">
                But of course, we owe you more of an explanation than just that.
              </p>
              <p className="text-[17.5] font-medium mb-5 leading-7">
                First, we make sure to understand exactly what you need — and
                not just your work needs, but your company culture, values, and
                business goals. A holistic vision of your requirements helps us
                channel our expertise in the right direction. We’ll work with
                you through the full life cycle of a project, from concept to
                creation, using both cutting edge and traditional tools.
              </p>
            </div>
            <div className="w-1/2 h-auto min-sm:w-full">
              <ReactPlayer
                url="https://www.youtube.com/watch?v=dq5BaowxAKY"
                playing={false}
                controls={false}
                width={"100%"}
                height={"100%"}
              />
            </div>
          </div>
          <p className="text-[17.5] text font-medium mb-5 leading-7 mt-9">
            That expertise we mentioned is what fuels the engine of our company
            and delivers the goods. We merge the skills of European and
            Vietnamese software experts to build solutions that stand the test
            of time.
          </p>
          <p className="text-[17.5] font-medium mb-5 leading-7">
            We attract and keep the best technical experts around to our
            development centre in Ha Noi, Vietnam. Our job is to help you stay
            ahead of the curve and outpace the competition. Our goal is to
            challenge the status quo.
          </p>
          <div className="grid grid-cols-3 gap-[5%] min-sm:grid-cols-1">
            <div className="flex flex-col ">
              <p className="w-[40%] mx-auto text-[90px] text-[#00b4ff] font-bold leading[80px]">
                18+
              </p>
              <span className="text-[34px] text-white py-[34px] text-center font-bold">
                Active Clients
              </span>
            </div>
            <div className="flex flex-col">
              <p className="w-[50%] mx-auto text-[90px] text-[#00b4ff] font-bold leading[80px]">
                125+
              </p>
              <span className="text-[34px] text-white py-[34px] text-center font-bold">
                Projects Done
              </span>
            </div>
            <div className="flex flex-col">
              <p className="w-[45%] mx-auto text-[90px] text-[#00b4ff] font-bold leading[80px]">
                250+
              </p>
              <span className="text-[34px] text-white py-[34px] text-center font-bold">
                Developers
              </span>
            </div>
          </div>
        </div>
      </div>
      {/*<div className="h-fit w-full bg-white py-20 ">*/}
      {/*  <div className="w-[70%] mx-auto text-center mt-9">*/}
      {/*    <h4 className="text-[30px] font-bold  text-center">*/}
      {/*      {"// What we serve"}*/}
      {/*    </h4>*/}
      {/*    <h2 className="font-bold pb-[10px] text-[50px] text-center mb-[10px]">*/}
      {/*      Business Industries*/}
      {/*    </h2>*/}
      {/*  </div>*/}
      {/*  <div*/}
      {/*    style={{ position: "relative", backgroundImage: `url(${map})` }}*/}
      {/*    className="bg-cover bg-center min-h-[500px] py-20 "*/}
      {/*  >*/}
      {/*    <Swiper*/}
      {/*      slidesPerView={3.5}*/}
      {/*      spaceBetween={30}*/}
      {/*      loop={true}*/}
      {/*      pagination={{*/}
      {/*        clickable: true,*/}
      {/*      }}*/}
      {/*      navigation={true}*/}
      {/*      modules={[Pagination, Navigation]}*/}
      {/*      breakpoints={{*/}
      {/*        320: {*/}
      {/*          slidesPerView: 1,*/}
      {/*          spaceBetween: 10,*/}
      {/*        },*/}
      {/*        480: {*/}
      {/*          slidesPerView: 1,*/}
      {/*          spaceBetween: 10,*/}
      {/*        },*/}
      {/*        640: {*/}
      {/*          slidesPerView: 2,*/}
      {/*          spaceBetween: 20,*/}
      {/*        },*/}
      {/*        768: {*/}
      {/*          slidesPerView: 2,*/}
      {/*          spaceBetween: 20,*/}
      {/*        },*/}
      {/*        1024: {*/}
      {/*          slidesPerView: 3,*/}
      {/*          spaceBetween: 30,*/}
      {/*        },*/}
      {/*        1280: {*/}
      {/*          slidesPerView: 3.5,*/}
      {/*          spaceBetween: 30,*/}
      {/*        },*/}
      {/*      }}*/}
      {/*      className="mySwiper"*/}
      {/*    >*/}
      {/*      <SwiperSlide>*/}
      {/*        <div*/}
      {/*          className="flex flex-row p-9 bg-white mx-10 "*/}
      {/*          style={{ position: "relative" }}*/}
      {/*        >*/}
      {/*          <div*/}
      {/*            className=" w[20%] h-full ml-[-36%] mt-4"*/}
      {/*            style={{*/}
      {/*              zIndex: 1000,*/}
      {/*              cursor: "pointer",*/}
      {/*            }}*/}
      {/*          >*/}
      {/*            <img*/}
      {/*              src={T2}*/}
      {/*              alt="t-2"*/}
      {/*              width={"120%"}*/}
      {/*              height={"100%"}*/}
      {/*              className="mx-auto"*/}
      {/*            />*/}
      {/*          </div>*/}
      {/*          <div*/}
      {/*            className="flex flex-col w-[80%] "*/}
      {/*            style={{*/}
      {/*              boxShadow: "0 4px 35px rgb(0 0 0 / 25%)",*/}
      {/*              padding: "30px 30px 30px 60px",*/}
      {/*              position: "absolute",*/}
      {/*            }}*/}
      {/*          >*/}
      {/*            <h4 className="text-[#011434] text-[21px] leading-6 font-bold mb-[10px]">*/}
      {/*              Telecom Software Development*/}
      {/*            </h4>*/}
      {/*            <p className="font-medium text-[14px]  leading-6">*/}
      {/*              Diversify the availability of your telecom services, drive*/}
      {/*              customer engagement, and reduce financial overhead.*/}
      {/*              Telecommunications are the backbone of the world’s modern*/}
      {/*              economy, and solid communications software keeps companies*/}
      {/*              up to speed with current trends.*/}
      {/*            </p>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </SwiperSlide>*/}
      {/*      <SwiperSlide>*/}
      {/*        <div*/}
      {/*          className="flex flex-row p-9 bg-white mx-10 "*/}
      {/*          style={{ position: "relative" }}*/}
      {/*        >*/}
      {/*          <div*/}
      {/*            className=" w[20%] h-full ml-[-36%] mt-4"*/}
      {/*            style={{*/}
      {/*              zIndex: 1000,*/}
      {/*              cursor: "pointer",*/}
      {/*            }}*/}
      {/*          >*/}
      {/*            <img*/}
      {/*              src={E2}*/}
      {/*              alt="t-2"*/}
      {/*              width={"120%"}*/}
      {/*              height={"100%"}*/}
      {/*              className="mx-auto"*/}
      {/*            />*/}
      {/*          </div>*/}
      {/*          <div*/}
      {/*            className="flex flex-col w-[80%] min-h-[310px]"*/}
      {/*            style={{*/}
      {/*              boxShadow: "0 4px 35px rgb(0 0 0 / 25%)",*/}
      {/*              padding: "30px 30px 30px 60px",*/}
      {/*              position: "absolute",*/}
      {/*            }}*/}
      {/*          >*/}
      {/*            <h4 className="text-[#011434] text-[21px] leading-6 font-bold mb-[10px]">*/}
      {/*              E-learning Software Development*/}
      {/*            </h4>*/}
      {/*            <p className="font-medium text-[14px]  leading-6">*/}
      {/*              Keep ahead of the competition in the booming E-learning*/}
      {/*              industry. We’ll help you offer seamless content distribution*/}
      {/*              and integrate global educational expertise — so your end*/}
      {/*              users get a program they understand and trust.*/}
      {/*            </p>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </SwiperSlide>*/}
      {/*      <SwiperSlide>*/}
      {/*        <div*/}
      {/*          className="flex flex-row p-9 bg-white mx-10 "*/}
      {/*          style={{ position: "relative" }}*/}
      {/*        >*/}
      {/*          <div*/}
      {/*            className=" w[20%] h-full ml-[-36%] mt-4"*/}
      {/*            style={{*/}
      {/*              zIndex: 1000,*/}
      {/*              cursor: "pointer",*/}
      {/*            }}*/}
      {/*          >*/}
      {/*            <img*/}
      {/*              src={T2}*/}
      {/*              alt="t-2"*/}
      {/*              width={"120%"}*/}
      {/*              height={"100%"}*/}
      {/*              className="mx-auto"*/}
      {/*            />*/}
      {/*          </div>*/}
      {/*          <div*/}
      {/*            className="flex flex-col w-[80%] min-h-[310px] "*/}
      {/*            style={{*/}
      {/*              boxShadow: "0 4px 35px rgb(0 0 0 / 25%)",*/}
      {/*              padding: "30px 30px 30px 60px",*/}
      {/*              position: "absolute",*/}
      {/*            }}*/}
      {/*          >*/}
      {/*            <h4 className="text-[#011434] text-[21px] leading-6 font-bold mb-[10px]">*/}
      {/*              Travel and Hospitality Software Development{" "}*/}
      {/*            </h4>*/}
      {/*            <p className="font-medium text-[14px]  leading-6">*/}
      {/*              With so many options available, a travel and hospitality*/}
      {/*              site has to be truly top-notch. You can win customers’*/}
      {/*              hearts by providing an outstanding UI and customer*/}
      {/*              experience — which we just happen to be experts in.*/}
      {/*            </p>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </SwiperSlide>*/}
      {/*      <SwiperSlide>*/}
      {/*        <div*/}
      {/*          className="flex flex-row p-9 bg-white mx-10 "*/}
      {/*          style={{ position: "relative" }}*/}
      {/*        >*/}
      {/*          <div*/}
      {/*            className=" w[20%] h-full ml-[-36%] mt-4"*/}
      {/*            style={{*/}
      {/*              zIndex: 1000,*/}
      {/*              cursor: "pointer",*/}
      {/*            }}*/}
      {/*          >*/}
      {/*            <img*/}
      {/*              src={E2}*/}
      {/*              alt="t-2"*/}
      {/*              width={"120%"}*/}
      {/*              height={"100%"}*/}
      {/*              className="mx-auto"*/}
      {/*            />*/}
      {/*          </div>*/}
      {/*          <div*/}
      {/*            className="flex flex-col w-[80%] min-h-[310px]"*/}
      {/*            style={{*/}
      {/*              boxShadow: "0 4px 35px rgb(0 0 0 / 25%)",*/}
      {/*              padding: "30px 30px 30px 60px",*/}
      {/*              position: "absolute",*/}
      {/*            }}*/}
      {/*          >*/}
      {/*            <h4 className="text-[#011434] text-[21px] leading-6 font-bold mb-[10px]">*/}
      {/*              eCommerce Software Development*/}
      {/*            </h4>*/}
      {/*            /!* <div className="text-[#00b4ff] text-[14px] leading-6 font-medium pb-4 h-3"></div> *!/*/}
      {/*            <p className="font-medium text-[14px]  leading-6">*/}
      {/*              If eCommerce was the future 20 years ago, it’s the standard*/}
      {/*              model now — and customers expect nothing but the best. We*/}
      {/*              provide the most innovative technological solutions to*/}
      {/*              increase customer engagement and conversion rate, all while*/}
      {/*              automating workflow to minimize your workload.*/}
      {/*            </p>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </SwiperSlide>*/}
      {/*    </Swiper>*/}
      {/*  </div>*/}
      {/*  <div className="w-[70%] mx-auto text-center py-9">*/}
      {/*    <h4 className="text-[30px] font-bold  text-center">*/}
      {/*      {"// Work with Us"}*/}
      {/*    </h4>*/}
      {/*    <h2 className="font-bold pb-[10px] text-[50px] text-center mb-[10px]">*/}
      {/*      Benefits*/}
      {/*    </h2>*/}
      {/*  </div>*/}
      {/*  <div className="w-[70%] mx-auto  py-9 flex flex-row min-sm:w-[85%]">*/}
      {/*    <div className="w-[46%]">*/}
      {/*      <img*/}
      {/*        src={isHovering[5] ? benefits1 : benefits1Hover}*/}
      {/*        alt="access"*/}
      {/*        width={"80%"}*/}
      {/*        onMouseEnter={() => onMouseEnter(5)}*/}
      {/*        onMouseLeave={() => onMouseLeave(5)}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="h-[100%] ">*/}
      {/*      <img src={border1} alt="border1" width={"100%"} height={"100%"} />*/}
      {/*    </div>*/}
      {/*    <div className="w-1/2 ml-4">*/}
      {/*      <h2 className="font-bold text-[30px]  leading-10 text-[#00b4ff] mb-3">*/}
      {/*        Access to Top Talent*/}
      {/*      </h2>*/}
      {/*      <p className="font-medium text-[17.5px]  mb-[10px] leading-7">*/}
      {/*        Our developers live and breathe software — they’re true experts in*/}
      {/*        their field. We know they’re the backbone of our company, so we*/}
      {/*        treat them right to keep them happy. A happy developer is a good*/}
      {/*        developer, able to focus 100% of their energy on your project.*/}
      {/*      </p>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div className="w-[70%] mx-auto  py-9 flex flex-row">*/}
      {/*    <div className="h-[100%]">*/}
      {/*      <img src={border2} alt="border2" width={"100%"} height={"100%"} />*/}
      {/*    </div>*/}
      {/*    <div className="w-1/2 ml-4">*/}
      {/*      <h2 className="font-bold text-[30px]  leading-10 text-[#00b4ff] mb-3">*/}
      {/*        Cost Efficiency*/}
      {/*      </h2>*/}
      {/*      <p className="font-medium text-[17.5px]  mb-[10px] leading-7">*/}
      {/*        Naturally, you want the best value for your money. That means*/}
      {/*        top-notch engineers and innovative solutions that don’t break the*/}
      {/*        bank. That’s us — the perfect combination of price and quality. We*/}
      {/*        shoulder the costs of our talent to minimize your outlays.*/}
      {/*      </p>*/}
      {/*    </div>*/}
      {/*    <div className="w-[46%]">*/}
      {/*      <img*/}
      {/*        src={isHovering[6] ? benefits2Hover : benefits2}*/}
      {/*        alt="access"*/}
      {/*        width={"80%"}*/}
      {/*        className="items-center ml-[15%]"*/}
      {/*        onMouseEnter={() => onMouseEnter(6)}*/}
      {/*        onMouseLeave={() => onMouseLeave(6)}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div className="w-[70%] mx-auto  py-9 flex flex-row">*/}
      {/*    <div className="w-[46%]">*/}
      {/*      <img*/}
      {/*        src={isHovering[7] ? benefits3Hover : benefits3}*/}
      {/*        alt="access"*/}
      {/*        width={"70%"}*/}
      {/*        onMouseEnter={() => onMouseEnter(7)}*/}
      {/*        onMouseLeave={() => onMouseLeave(7)}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="h-[58%]">*/}
      {/*      <img src={border3} alt="border1" width={"100%"} height={"100%"} />*/}
      {/*    </div>*/}
      {/*    <div className="w-1/2 ml-4">*/}
      {/*      <h2 className="font-bold text-[30px]  leading-10 text-[#00b4ff] mb-3">*/}
      {/*        Value-driven Engineers*/}
      {/*      </h2>*/}
      {/*      <p className="font-medium text-[17.5px]  mb-[10px] leading-7">*/}
      {/*        Our engineers are rockstars. They love what they do — creating*/}
      {/*        software and solutions that meet your values and goals.*/}
      {/*      </p>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div className="w-[70%] mx-auto  py-9 flex flex-row">*/}
      {/*    <div className="h-[70%]">*/}
      {/*      <img src={border4} alt="border1" width={"100%"} height={"100%"} />*/}
      {/*    </div>*/}
      {/*    <div className="w-[56%] ml-4">*/}
      {/*      <h2 className="font-bold text-[30px]  leading-10 text-[#00b4ff] mb-3">*/}
      {/*        Value-driven Engineers*/}
      {/*      </h2>*/}
      {/*      <p className="font-medium text-[17.5px]  mb-[10px] leading-7">*/}
      {/*        Our engineers are rockstars. They love what they do — creating*/}
      {/*        software and solutions that meet your values and goals.*/}
      {/*      </p>*/}
      {/*    </div>*/}
      {/*    <div className="w-[40%]">*/}
      {/*      <img*/}
      {/*        src={isHovering[8] ? benefits4Hover : benefits4}*/}
      {/*        alt="access"*/}
      {/*        width={"80%"}*/}
      {/*        className="ml-16"*/}
      {/*        onMouseEnter={() => onMouseEnter(8)}*/}
      {/*        onMouseLeave={() => onMouseLeave(8)}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}

      <div
        style={{ backgroundImage: `url(${backgroudTogether})` }}
        className="bg-black opacity-0.3 w-full  h-fit bg-no-repeat bg-cover"
      >
        <div
          style={{
            fontSize: 24,
            color: "#000",
            textAlign: "center",
            zIndex: 500,
            padding: "100px 0",
          }}
          className="w-[70%] mx-auto py-9  "
        >
          <h4 className="text-[22px]  font-bold pb-3 text-white">
            {"// Top-quality code is just the beginning"}
          </h4>
          <h2 className="text-[50px] font-bold pb-2 text-white">
            Let’s work together
          </h2>
          <div className="flex justify-center gap-6 items-center mt-9 min-sm:flex-col">
            <Link
              to="get-started"
              className="rounded-[8px] w-[270px] font-bold   border-[1px] border-white text-white py-4 px-10  text-[22px]  hover:bg-[#00eeff] "
            >
              Get a Quote
            </Link>
            <Link
              to="get-started"
              className="rounded-[8px] w-[270px] font-bold border-0  text-white py-4 px-10  text-[22px] bgi-gradion "
            >
              Contact Us
            </Link>
          </div>
        </div>
      </div>
      {/* <div className="h-fit w-full bg-white py-20">
        <div className="w-[70%] mx-auto text-center mt-9">
          <h4 className="text-[30px] font-bold  text-center">
            {"// What we serve"}
          </h4>
          <h2 className="font-bold pb-[10px] text-[50px] text-center ">
            Business Industries
          </h2>
          <h5 className="text-[18px] text-[#00b4ff] leading-[30px] font-normal mb-[10px]">
            Success stories originated in CfiDev.
          </h5>
        </div>
        <div
          style={{ position: "relative", backgroundImage: `url(${map})` }}
          className="bg-cover bg-center min-h-[500px] py-20"
        >
          <Swiper
            slidesPerView={2}
            spaceBetween={50}
            loop={true}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper "
          >
            <SwiperSlide>
              <div
                className="flex flex-row p-9 bg-white mx-10 "
                style={{
                  boxShadow: "0 4px 35px rgb(0 0 0 / 25%)",
                  cursor: "pointer",
                }}
              >
                <div className="h-[15%] w[15%] ">
                  <img
                    src={secotiLogo}
                    alt="secoti-logo"
                    width={"80%"}
                    height={"80%"}
                    className="mx-auto"
                  />
                </div>
                <div className="flex flex-col w-[80%] ml-[5%]">
                  <h4 className="text-[#011434] text-[24px] leading-6 font-bold mb-6">
                    SECOTI
                  </h4>
                  <p className="font-medium text-[17.5px]  mb-11 leading-7">
                    “I greatly appreciate the service that CFI has been
                    providing to IPTEQ over the last 3 years. The attention that
                    the development team has been giving to our projects is
                    remarkable.”
                  </p>
                  <p className="font-medium text-[17.5px]  mb-[10px] leading-7">
                    Santosh J.{" "}
                  </p>
                  <a
                    href="http://kitchenpalapp.com"
                    className="flex flex-row h-[30px]"
                  >
                    <div className="h-1/2 w-[85%] flex justify-end items-center mt-[6px]">
                      <img src={iconRight} alt="icon-right" height={"50%"} />
                    </div>
                    <p className="w-[15%] text-4 text-[#00b4ff] font-normal flex justify-end">
                      Learn more
                    </p>
                  </a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="flex flex-row p-9 bg-white mx-10 "
                style={{
                  boxShadow: "0 4px 35px rgb(0 0 0 / 25%)",
                  cursor: "pointer",
                }}
              >
                <div className="h-[15%] w[15%] ">
                  <img
                    src={testimonial1}
                    alt="secoti-logo"
                    width={"80%"}
                    height={"80%"}
                    className="mx-auto"
                  />
                </div>
                <div className="flex flex-col w-[80%] ml-[5%]">
                  <h4 className="text-[#011434] text-[24px] leading-6 font-bold mb-6">
                    KitchenPal
                  </h4>
                  <p className="font-medium text-[17.5px]  mb-4 leading-7">
                    “I am delighted to work with CFI’s team. They developed my
                    mobile app and back-end from scratch in a timely and cost
                    efficient manner. I definitely recommend CFI to any startup
                    on the lookout for a reliable software company.”
                  </p>
                  <p className="font-medium text-[17.5px]  mb-[10px] leading-7">
                    Matthieu T.
                  </p>
                  <a
                    href="http://kitchenpalapp.com"
                    className="flex flex-row h-[30px]"
                  >
                    <div className="h-1/2 w-[85%] flex justify-end items-center mt-[6px]">
                      <img src={iconRight} alt="icon-right" height={"50%"} />
                    </div>
                    <p className="w-[15%] text-4 text-[#00b4ff] font-normal flex justify-end">
                      Learn more
                    </p>
                  </a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="flex flex-row p-9 bg-white mx-10 min-height-[316px]"
                style={{
                  boxShadow: "0 4px 35px rgb(0 0 0 / 25%)",
                  cursor: "pointer",
                }}
              >
                <div className="h-[15%] w[15%] ">
                  <img
                    src={testimonial2}
                    alt="secoti-logo"
                    width={"80%"}
                    height={"80%"}
                    className="mx-auto"
                  />
                </div>
                <div className="flex flex-col w-[80%] ml-[5%]">
                  <h4 className="text-[#011434] text-[24px] leading-6 font-bold mb-6">
                    IPTEQ
                  </h4>
                  <p className="font-medium text-[17.5px]  mb-11 leading-7 ">
                    “I greatly appreciate the service that CFI has been
                    providing to IPTEQ over the last 3 years. The attention that
                    the development team has been giving to our projects is
                    remarkable.”
                  </p>
                  <p className="font-medium text-[17.5px]  mb-[10px] leading-7">
                    Santosh J.
                  </p>
                  <a href="http://ipteq.com" className="flex flex-row h-[30px]">
                    <div className="h-1/2 w-[85%] flex justify-end items-center mt-[6px]">
                      <img src={iconRight} alt="icon-right" height={"50%"} />
                    </div>
                    <p className="w-[15%] text-4 text-[#00b4ff] font-normal flex justify-end">
                      Learn more
                    </p>
                  </a>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div> */}
      {/* <div style={{ position: "relative" }}>
        <img
          style={{
            objectFit: "cover",
          }}
          className="h-fit w-full "
          src={backgroundConsultation}
          alt="background-consultation"
        />
        <div
          style={{
            position: "absolute",
            fontSize: 24,
            color: "#000",
            textAlign: "center",
            zIndex: 500,
            top: "50%",
            left: "50%",
            translate: "-50% -50%",
          }}
          className="w-[70%] mr-auto py-9 "
        >
          <h4 className="text-[22px]  font-bold pb-3 text-white">
            {"// Drop us a line! We are here to answer your questions 24/7"}
          </h4>
          <h2 className="text-[50px] font-bold pb-2 text-white">
            Need a Consultation?
          </h2>
          <div className="flex justify-center gap-6 items-center mt-9">
            <button className="rounded-[8px] w-[270px] font-bold  border-[1px] border-white text-white py-4 px-10  text-[22px]  hover:bg-[#00eeff] ">
              Free Quote
            </button>
            <button className="rounded-[8px] w-[270px] font-bold border-0  text-white py-4 px-10  text-[22px] bgi-gradion ">
              Contact Us
            </button>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Index;
