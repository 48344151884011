import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import { BsWhatsapp } from "react-icons/bs";
import { Toaster } from "react-hot-toast";

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <Toaster />
      <div className="flex-1 min-h-80">{children}</div>
      <a
        className={
          "fixed bottom-[40px] right-[40px] w-[60px] h-[60px] bg-green-600 rounded-full text-center text-white drop-shadow-2xl"
        }
        href={"https://wa.me/+9710543959830"}
        onClick={() =>
          window.gtag("event", "whatsapp", {
            event_category: "contact",
            event_label: "whatsapp",
            send_to: "AW-16681535148/vSb2CL2_os0ZEKyNsJI-",
          })
        }
        target={"_blank"}
        rel={"noopener noreferrer"}
      >
        <BsWhatsapp className={"mt-5 ml-5 text-xl"} />
      </a>
      <Footer />
    </div>
  );
};

export default Layout;
