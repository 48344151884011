import React from 'react';
import ProjectResults from "../components/ProjectResults";
import mampedia from "../assets/mampedia.png";

const ReactNative = () => {
    const listProject=[

        {
            img: mampedia,
            name: "Mampedia App",
            language: "React native, Nestjs",
            link:'https://mampedia.com/'

        }
    ]
    return (
        <div>
            <ProjectResults
                name={"React Native App Development"}
                subtext={
                    "We build cross-platform apps that run on both iOS and Android from a single code base. Code written in React Native is operating system–agnostic, yet apps written in React feel just like native apps written in Java or Swift. Using React Native speeds up mobile app development for iOS and Android"
                }
                labelProject={'Out React Native App'}
                listProject={listProject}
            />
        </div>
    );
};

export default ReactNative;
