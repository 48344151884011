import React from "react";
import ShareLayout from "./Layout/ShareLayout";

import backgroundConsultation from "../assets/images/background-consultation.jpeg";
import { Link } from "react-router-dom";
import { questions, listProcess } from "../utils/mock-data";
const ProjectResults = (props) => {
  const { name, subtext, labelProject, listProject } = props;

  return (
    <div>
      <ShareLayout
        headerImage={
          "https://www.smartdev.com/wp-content/uploads/2021/10/banner-iot-service.jpg"
        }
        headerText={name}
        headerSubText={<div className={"w-3/4 md:hidden"}>{subtext}</div>}
      ></ShareLayout>
      <div className="bg-white w-4/5 mx-auto h-fit pb-20">
        <h2 className="text-[22px] text-[#0c1a43] font-bold py-4 uppercase">
          {labelProject}
        </h2>
        <hr className=" my-6 border-[1px]" />
        {listProject && (
          <div className="flex flex-wrap gap-[5.5%] min-sm:flex-col gap-y-5">
            {listProject?.map((item, index) => (
              <div
                key={index}
                className="flex flex-col w-1/5 min-sm:w-4/5 min-sm:mx-auto"
              >
                <div className="w-full  ">
                  <img
                    src={item.img}
                    alt="project-img"
                    width={"100%"}
                    height={"100%"}
                  />
                </div>
                <a
                  href={item.link}
                  className="text-[18px] font-bold text-[#333333] cursor-pointer"
                  target="_blank"
                  rel="noreferrer"
                >
                  {item.name}
                </a>
                <p className="text-[14px] text-[#666] font-medium leading-7.5">
                  {item.language}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="bg-[#f5f5f5] h-fit w-full">
        <div className=" w-4/5 mx-auto py-10">
          <h2 className="text-[22px] text-[#0c1a43] font-bold py-4 ">
            OUR PROCESS
          </h2>
          <hr className=" my-6 border-[1px]" />
          <div className="flex flex-wrap gap-[5.5%] min-sm:flex-col">
            {listProcess?.map((item, index) => (
              <div
                key={index}
                className="flex flex-col w-1/5 h-auto text-center min-sm:w-full"
              >
                <div className="w-100% mx-auto py-8">
                  <img
                    src={item.link}
                    alt="project-img"
                    width={"100%"}
                    height={"auto"}
                  />
                </div>
                <h2 className="text-[20px] font-bold text-[#666] py-5">
                  {item.number}
                </h2>
                <p className="text-[17.5px] text-[#666] font-medium leading-7.5">
                  {item.name}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        style={{ backgroundImage: `url(${backgroundConsultation})` }}
        className="bg-black opacity-0.3 w-full  h-fit bg-no-repeat bg-cover"
      >
        <div
          style={{
            fontSize: 24,
            color: "#000",
            textAlign: "center",
            zIndex: 500,
            padding: "100px 0",
          }}
          className="w-[70%] mx-auto py-9  "
        >
          <h4 className="text-[22px]  font-bold pb-3 text-white">
            {"// Drop us a line! We are here to answer your questions 24/7"}
          </h4>
          <h2 className="text-[50px] font-bold pb-2 text-white">
            Do you want to get your project started?
          </h2>
          <div className="flex justify-center gap-6 items-center mt-9 min-sm:flex-col">
            <Link
              to="/get-started"
              className="rounded-[8px] w-[270px] font-bold   border-[1px] border-white text-white py-4 px-10  text-[22px]  hover:bg-[#00eeff] "
            >
              Let's Talk
            </Link>
            <a
              href="#contact"
              className="rounded-[8px] w-[270px] font-bold border-0  text-white py-4 px-10  text-[22px] bgi-gradion "
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
      <div className="w-4/5 h-fit bg-white py-20 mx-auto">
        <h2 className="text-[26px] text-[#333] font-bold py-4">
          Frequently Asked Questions
        </h2>
        {questions?.map((item, index) => (
          <div
            key={index}
            className="flex flex-wrap gap-[5%] pt-5 w-full min-sm:flex-col "
          >
            <h4 className="text-[18px] text-[#0c1a43] font-bold w-[30%] leading-7 min-sm:w-full">
              {item.question}
            </h4>
            <p
              className="text-[17.5px] text-[#7e8f9a] font-medium pb-5 w-[65%] leading-7 min-sm:w-full "
              style={{ borderBottom: "1px solid #7e8f9a" }}
            >
              {item.answer}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectResults;
