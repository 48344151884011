import React from "react";
import ShareLayout from "../components/Layout/ShareLayout";
const beList = [
  {
    title: "// JavaScript",
    name: "Node.js Development",
    content:
      "As the name suggests, Node.js is a JavaScript-based framework. It’s a great fit for speedy, real-time applications — think games, chat, and similar applications that require highly responsive interfaces. Lightweight, efficient, and scalable, Node.js excels at managing distributed, data-heavy applications.This allows for smooth connections between client and server, with two-way communication.",
    image: "https://smartdev.com/wp-content/uploads/2022/04/be-2.jpg",
  },
  {
    title: "// Python",
    name: "Django Development",
    content:
      "As the name suggests, Django is a Python-based framework. It’s a great fit for speedy, real-time applications — think games, chat, and similar applications that require highly responsive interfaces. Lightweight, efficient, and scalable, Node.js excels at managing distributed, data-heavy applications.This allows for smooth connections between client and server, with two-way communication.",
    image:
      "https://media.dev.to/cdn-cgi/image/width=1000,height=420,fit=cover,gravity=auto,format=auto/https%3A%2F%2Fdev-to-uploads.s3.amazonaws.com%2Fuploads%2Farticles%2F0ssccolwd40uf93owseo.png",
  },
];
const Backend = () => {
  return (
    <ShareLayout
      headerImage="https://smartdev.com/wp-content/uploads/2022/04/banner-be-service.jpg"
      headerText="backend development"
      headerSubText={
        <p className="md:hidden">
          "Any good piece of software needs rock-solid backend development to
          give both end users and companies reliable stability and performance.
          And hey, guess what — we do just that."
        </p>
      }
    >
      <div className="space-y-9 pb-20">
        {beList.map((item, index) => (
          <div
            key={index}
            className="flex justify-between items-center md:flex-wrap md:justify-normal md:px-4 md:text-center"
          >
            <div className="left">
              <h4 className="text-title text-3xl font-bold  ">{item.title}</h4>

              <h1 className="text-title text-4xl font-bold my-3 capitalize ">
                {item.name}
              </h1>
              <p className="w-3/5 text-grey text-lg my-6 md:w-full">
                {item.content}
              </p>
            </div>
            <img src={item.image} alt={item.title} width={435} />
          </div>
        ))}
      </div>
    </ShareLayout>
  );
};

export default Backend;
