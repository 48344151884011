import React from "react";
import { techList } from "../../utils/mock-data";

const TechStack = () => {
  return (
    <div className={"text-center w-[75%] mx-auto"}>
      <h1 className={"text-5xl my-4 font-bold"}>Technologies We Rule</h1>
      <div className="grid grid-cols-4 gap-8 mt-10">
        {techList.map((tech) => (
          <img
            alt={tech}
            src={tech}
            key={tech}
            width={120}
            height={120}
            className={"object-fill"}
          />
        ))}
      </div>
    </div>
  );
};

export default TechStack;
