import React from "react";

const OurApproach = () => {
  return (
    <div
      className="p-8 "
      style={{
        boxShadow: "0 .375em 2.8125em 0 #d2d5d9",
      }}
    >
      <div className="mb-5 text-[21px] text-black">Our Approach</div>
      <div className="flex flex-col gap-6">
        <div>
          <h2 className="pb-2 text-xl font-bold text-black">
            Initial Workshop
          </h2>
          <div className="flex flex-col gap-5">
            <p>
              With Scrum projects, it is often assumed that the team(s) can
              start developing from the first day of the project. However, all
              projects need a kick-off/initiation phase. In our experience, the
              requirements in the Product Backlog will not have been detailed
              into User Stories, nor the team fully formed for when the green
              light for a project is given.
            </p>
            <p>
              As such, we will initiate a kick-off/initiation phase, or a
              ‘Sprint 0.’ During Sprint 0, the project fundamentals need to be
              set up including defined elements such as access, environments,
              communications, tooling, reporting, and more. Crucially, the
              necessary deliverables must be agreed upon to get started.
            </p>
            <p>
              The initial workshop is facilitated by the service leadership from
              our clients in conjunction with our offshore development partner
              CfiDev. The core aim is to agree and capture the majority of the
              high-level requirements for the Product Backlog.
            </p>
            <p>
              For large projects or where there is not a clear view of what is
              needed, the initial workshop will also look to confirm the
              approach and governance measures to establish the requirements
              breakdown and planning will be tailored accordingly (aim to
              complete within 2 weeks). The Product Backlog will also be
              prioritised to prevent/minimise any development disruption.
            </p>
            <p>The high-level outputs to be established during Sprint 0 are:</p>
            <ul className=" list-disc pl-8 leading-[30px]">
              <li>Create the Team (size, types, location etc.)</li>
              <li>
                Develop a Common Vision (level of details, agreement, formality)
              </li>
              <li>
                Review & adopt any standard Enterprise guidelines, templates
              </li>
              <li>Establish governance, quality assurance structures</li>
              <li>
                Explore Initial Scope (types of modelling, modelling strategy,
                non-functional requirements, work items strategy)
              </li>
              <li>
                Identify the Initial Architectural/Technical Strategy (level of
                detail, types, modelling strategy) Develop Initial Release Plan
              </li>
              <li>Form Work Environment (physical and/or virtual, toolsets)</li>
              <li>Identify Risks</li>
              <li>Identify areas of development risk for early development</li>
              <li>Establish mechanisms to support Knowledge Transfer</li>
              <li>Outline roles and communication.</li>
            </ul>
          </div>
        </div>
        <div>
          <h2 className="pb-2 text-xl font-bold text-black">
            Requirement collection and recommendations
          </h2>
          <div className="flex flex-col gap-5">
            <p>
              Requirements set project goals and guide developers through coding
              and testing. Getting the requirements well understood and complete
              is critical to a project’s success. Wrong or incomplete
              requirements can create project delays, cost overruns, and poor
              user acceptance or adoption — even project failure. We want to
              avoid that, as we’re sure you do too. Fortunately, this is an
              aspect we’re well-versed in, so we’ll make sure this process is
              entirely clear and transparent.
            </p>
            <p>
              Our approach looks to capture as many details as possible from
              inception so that there is less rework, as well as more
              preparedness and clarity. The information that is collected during
              the requirements phase becomes a critical framework to build the
              requirement-gathering document. Given Agile approaches, regardless
              of methodology, will never capture all the required details that
              become clearer in the development phase. Consequently, the
              requirement-gathering document is a living document updated and
              reviewed within each and every sprint.
            </p>
            <p>
              We adhere to the following well established and recognisable
              4-component approach to capture and break down the requirements
              into solution ready scopes of work:
            </p>
            <p>
              1. Developing a vision for the high-level details — The objective
              of developing a vision is to identify the main theme, a high-level
              vision and scope, the target users, the main goals, and to create
              a high-level backlog. We conduct a Stakeholder interviews
              workshop, role playing along with out-of-the-box methods like
              Vision Statement in a product box, user roles (personas), use case
              modelling, process diagrams and UI flow and context diagrams to
              support the information capture at this stage.
            </p>
            <p>
              2. Requirements are broken into disparate buckets of work — This
              stage focuses on breadth rather than depth defining the major
              items of development (Epics). We look to conduct requirement
              brainstorming techniques through story writing workshops, post-it
              note brainstorming and other techniques to get the big picture.
            </p>
            <p>
              3. Breakdown of features into user story — Requirement breakdown
              divides existing epics into smaller user stories. We break down
              the requirements using techniques such as CRUD, acceptance test
              slicing and process steps. Dependencies between each of the Epics
              and Stories are outlined. Epics and Stories are slotted into
              blocks of development time, called Sprints, for development.
            </p>
            <p>
              4. Defining detailed requirements — User Stories are further split
              into individual tasks along with acceptance tests and UI
              prototypes. We adopt techniques such as acceptance tests, test
              scenarios, UI prototyping, wireframing, example tables, and
              activity diagrams. The tasks are then prioritised depending on the
              business demand.
            </p>
          </div>
        </div>
        <div>
          <h2 className="pb-2 text-xl font-bold text-black">
            Full development process
          </h2>
          <div className="pb-4 font-bold">
            From Sprint 0 to the Final Sprint
          </div>
          <div className="flex flex-col gap-5">
            <div>
              Our projects work in Sprints, components of work dedicated to
              particular tasks or series of tasks. Sprint 0, for example,
              results in a comprehensive project plan. After Sprint 0, we will
              deliver:
            </div>
            <ul className=" list-disc pl-8 text-[18px] leading-[30px]">
              <li>A functional piece of code, however small</li>
              <li>
                A feature prioritisation plan or a list of project stories
              </li>
              <li>A release plan, which assigns each story to a Sprint</li>
            </ul>
            <p>
              At the end of each Sprint, we’ll show you what we’ve accomplished
              by showing you the partially complete version of your project.
              Sprints are coordinated by daily Scrums, with the Scrum Leader
              being the main point of contact for all communication outside the
              scrum team. They keep stakeholders, project owners, and team
              members all on the same page and help maintain documentation.
              We’re all about accountability and transparency, and you deserve
              to know just what’s happening through effective and relevant
              communication.
            </p>
          </div>
        </div>
        <div>
          <div className="pb-4 font-bold">Publication</div>
          <p>
            After passing the User Acceptance Test (UAT), the product will be
            available to end users and will be deployed in a production
            environment. In other words, it’s done and ready to go.
          </p>
        </div>
        <div>
          <div className="pb-4 font-bold">Maintenance and Improvements</div>
          <p>
            We stand by our work and our clients, offering post-release support
            and enhancement services to maintain or improve any product or
            product features as necessary. This helps our clients navigate any
            unanticipated challenges when the product hits the market and
            afterwards. The duration, format and team allocation are dependent
            on the release strategy and will be established and evaluated
            constantly throughout the engagement.
          </p>
        </div>
      </div>
    </div>
  );
};

export default OurApproach;
