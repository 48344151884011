import iconAnalytics from "../assets/images/icon-analytics.svg";
import iconSchedule from "../assets/images/icon-schedule.svg";
import iconWorkflow from "../assets/images/icon-workflow.svg";
import iconAgreement from "../assets/images/icon-agreement.svg";
import smartEdit from "../assets/smartEdit.png";
import istick from "../assets/isticker.png";
import cfi from "../assets/cfi.png";
import nf from "../assets/nf.png";
import laPlan from "../assets/la_plancha.png";
import light from "../assets/smartlight.png";
import la from "../assets/la_plancha.png";
import mampedia from "../assets/mampedia.png";
import aws from "../assets/tech/aws.jpeg";
import django from "../assets/tech/django.png";
import figma from "../assets/tech/figma.png";
import firebase from "../assets/tech/firebase.jpg";
import nest from "../assets/tech/nest.png";
import react from "../assets/tech/react.png";
import flutter from "../assets/tech/flutter.png";
import postgres from "../assets/tech/postgres.png";
export const listOptionFooter = [
  {
    tile: "Mobile",
    result: [
      {
        name: "Flutter",
        url: "/flutter",
      },
      {
        name: "React Native",
        url: "/react-native",
      },
    ],
  },
  {
    tile: "Front-end",
    result: [
      {
        name: "React",
        url: "/react-project",
      },
    ],
  },
  {
    tile: "Back-end",
    result: [
      {
        name: "Python",
        url: "/python",
      },
    ],
  },
  {
    tile: "Others",
    result: [
      {
        name: "Contact Us",
        url: "/get-started",
      },
      {
        name: "FAQ",
        url: "/faq",
      },
    ],
  },
];
export const listProcess = [
  {
    link: iconAnalytics,
    number: 1,
    name: "Project Analysis",
  },
  {
    link: iconSchedule,
    number: 2,
    name: "Project Planning",
  },
  {
    link: iconWorkflow,
    number: 3,
    name: "Development Phase",
  },
  {
    link: iconAgreement,
    number: 4,
    name: "Ongoing Support",
  },
];
export const questions = [
  {
    question: "What are the accepted payment methods?",
    answer: "We only accept bank transfer.",
  },
  {
    question: "Do I own the source code of my project?",
    answer: "Yes, you do.",
  },
  {
    question: "Can CfiDev work on an existing project?",
    answer: "We can resume the work on existing projects.",
  },
  {
    question: "Can CfiDev produce the design of my apps / website?",
    answer: "Yes, we offer professional UX/UI design services.",
  },
  {
    question: "Does CfiDev manage the deployment of my project?",
    answer:
      "Yes, we manage the deployment and the publication of your project.",
  },
  {
    question: "Can CfiDev sign a NDA before exchanging information?",
    answer: "Yes.",
  },
  {
    question: "Does CfiDev offer maintenance plans?",
    answer: "We sure do. We can adapt our plan to your need.",
  },
];
export const listProjects = [
  {
    label: "Web Application",
    projects: [
      {
        img: cfi,
        name: "CFI Timekeeping System",
        language: "React, Python",
      },
      {
        img: nf,
        name: "Nature Feerique",
        language: "React, Python",
        link: "https://naturefeerique.com",
      },
      {
        img: laPlan,
        name: "La Plancha website",
        language: "Python, Fluter, React",
      },
    ],
  },
  {
    label: "Mobile Application",
    projects: [
      {
        img: mampedia,
        name: "Mampedia App",
        language: "React native, Nestjs",
        link: "https://mampedia.com/",
      },
      {
        img: smartEdit,
        name: "Smart Edit App",
        language: "Flutter, Python",
        link: "https://play.google.com/store/apps/details?id=com.abi.remove.plus.background",
      },
      {
        img: istick,
        name: "ISticker App",
        language: "Flutter",
        link: "https://play.google.com/store/apps/details?id=com.isticker.sticker.maker",
      },

      {
        img: light,
        name: "SmartLight App",
        language: "Flutter",
      },
      {
        img: la,
        name: "La Planche App",
        language: "Flutter",
      },
    ],
  },
];
export const techList = [
  figma,
  flutter,
  firebase,
  react,
  nest,
  django,
  aws,
  postgres,
];
export const demoAppList = [
  "https://firebasestorage.googleapis.com/v0/b/securip-dev.appspot.com/o/test_image%2F1.png?alt=media&token=d67ebdbb-fa96-4c5c-a23b-323d29898f2a",
  "https://firebasestorage.googleapis.com/v0/b/securip-dev.appspot.com/o/test_image%2F2.png?alt=media&token=b8a9d201-7de9-4db6-90ee-8a015c5add07",
  "https://firebasestorage.googleapis.com/v0/b/securip-dev.appspot.com/o/test_image%2F3.png?alt=media&token=a4676654-d55c-4897-bb74-874e80b64321",
  "https://firebasestorage.googleapis.com/v0/b/securip-dev.appspot.com/o/test_image%2F4.png?alt=media&token=1cfc0e5a-b27a-4b98-87cf-71b567676aee",
];
