import React, { useState } from "react";
import { twMerge } from "tailwind-merge";
import Banner from "../assets/images/banner-iot-service.jpg";
import AgileTeams from "../components/faq/AgileTeams";
import GeneralInformation from "../components/faq/GeneralInformation";
import OurApproach from "../components/faq/OurApproach";
const Faq = () => {
  const [faqIndex, setFaqIndex] = useState(1);

  const handleChangeFaqIndex = (value) => {
    if (faqIndex !== value) setFaqIndex(value);
  };

  return (
    <div>
      <div
        className="object-cover w-full min-h-[610px] h-full pt-20 pb-10 lg:min-h-full"
        style={{
          backgroundImage: `url(${Banner})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="py-24 px-20 md:px-10 max-w-[1440px] 2xl:px-5 mx-auto">
          <div className="flex flex-col gap-8 text-white ">
            <h2 className="text-[60px] md:text-[32px] font-bold md:text-center">
              FAQS
            </h2>
            <span className="max-w-[70%] text-[18px] md:hidden">
              Welcome to CfiDev! You’ve found our FAQ page, which is a good
              place to start if you’re just learning about us. Below, you’ll
              find information on who we are, what we do, and how we can help
              you. Welcome, bienvenue, willkommen, and so on.
            </span>
          </div>
        </div>
      </div>
      <div className="max-w-[1440px] flex flex-col gap-32 md:gap-20 sm:gap-10 py-20 md:py-16 mx-auto xl:w-full px-10">
        <div className="flex gap-[5.5%] md:flex-col md:gap-10">
          <div className="flex flex-col border-l border-l-primary h-fit">
            <div
              className={twMerge(
                "text-xl font-bold p-[14px] pr-0 cursor-pointer transition duration-300 ease-in-out",
                faqIndex === 1 && "text-primary"
              )}
              onClick={() => handleChangeFaqIndex(1)}
            >
              General Information
            </div>
            <div
              className={twMerge(
                "text-xl font-bold p-[14px] pr-0 cursor-pointer transition duration-300 ease-in-out",
                faqIndex === 2 && "text-primary"
              )}
              onClick={() => handleChangeFaqIndex(2)}
            >
              Our Approach
            </div>
            <div
              className={twMerge(
                "text-xl font-bold p-[14px] pr-0 cursor-pointer transition duration-300 ease-in-out",
                faqIndex === 3 && "text-primary"
              )}
              onClick={() => handleChangeFaqIndex(3)}
            >
              Agile Teams
            </div>
          </div>
          <div className="flex-1 text-[#7e8f9a]  text-[18px]">
            {faqIndex === 1 && <GeneralInformation />}
            {faqIndex === 2 && <OurApproach />}
            {faqIndex === 3 && <AgileTeams />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
