import React from "react";
import ShareLayout from "../components/Layout/ShareLayout";

import second from "../assets/mobile/2.png";
import one from "../assets/mobile/1.png";
const mobileList = [
  {
    title: "Flutter app development",
    desc: "Flutter is Google’s new software development kit, and it’s surging in demand and popularity. It’s popular with engineers because it makes the development of both Android and iOS apps easier and faster while maintaining quality. Flutter is a cutting-edge option that our developers use to equal or surpass native apps in terms of look, feel, and performance.",
    pros: "flutter app pros",
    proslist: (
      <>
        <li>Cross platform support</li>
        <li>Fast development</li>
        <li>Great user experience</li>
        <li>Open source supported by Google</li>
      </>
    ),
    image: second,
  },{
    title: "React native app development",
    desc: "React Native apps are currently the most in-demand variety out there. A programmer can build a hybrid app that works on both Android and iOS using the well-loved and well-understood coding language of JavaScript. It’s an excellent choice for smaller budgets due to its cost and time efficiency",
    pros: "React native app pros",
    proslist: (
      <>
        <li>Supports both platform</li>
        <li>Feature native components for maximum performance</li>
        <li>Fast development cycle</li>
        <li>Cost efficient</li>
      </>
    ),
    image: one,
  },
];
const Mobile = () => {
  return (
    <ShareLayout
      headerImage={
        "https://smartdev.com/wp-content/uploads/2022/04/banner-mobile-sv.jpg"
      }
      headerText={"Mobile App Development"}
      headerSubText={
        <div
          style={{ width: "70%", fontSize: "17px", fontWeight: 500 }}
          className="md:hidden"
        >
          <p className="text-justify">
            Looking for apps for Android or iOS? Look no further. That’s our
            wheelhouse. Our expert developers can make an app to help you
            improve many aspects of your business — whatever you need, really.
            You’ll get a custom app that ticks all the right boxes.
          </p>
          <br />
          <p className="text-justify">
            We code in native Android, Swift, Reactive Native and Flutter, and
            cover the whole spectrum of coding — from backend to UI and
            everything in between.
          </p>
        </div>
      }
    >
      <div className="space-y-12 mb-24 md:px-8">
        {mobileList.map((item, index) => (
          <div
            key={index}
            className="flex justify-between items-center md:flex-wrap md:justify-normal "
          >
            <div className="left">
              <h4 className="text-title text-3xl font-bold  ">
                {" "}
                {"//"} Multi App
              </h4>

              <h1 className="text-title text-4xl font-bold my-3 capitalize ">
                {item.title}
              </h1>
              <p className="w-3/5 text-grey text-lg my-6 md:w-full ">
                {item.desc}
              </p>
              <strong className="text-title capitalize">{item.pros}:</strong>
              <ul className="text-grey list-disc px-10 pt-3">
                {item.proslist}
              </ul>
            </div>
            <img src={item.image} alt={item.title} />
          </div>
        ))}
      </div>
    </ShareLayout>
  );
};

export default Mobile;
