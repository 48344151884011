import ProjectResults from "../components/ProjectResults";
import React from "react";

import smartEdit from "../assets/smartEdit.png";
import cfi from "../assets/cfi.png";
import nf from "../assets/nf.png";
import laPlan from "../assets/la_plancha.png";
import istick from "../assets/isticker.png";
const Python = () => {
  const labelProject = "OUR PYTHON APPS";
  const listProject = [
    {
      img: smartEdit,
      name: "Smart Edit App",
      language: "Flutter, Python",
      link: "https://play.google.com/store/apps/details?id=com.abi.remove.plus.background",
    },
    {
      img: istick,
      name: "ISticker App",
      language: "Flutter,Python",
      link: "https://play.google.com/store/apps/details?id=com.isticker.sticker.maker",
    },
    {
      img: cfi,
      name: "CFI Timekeeping System",
      language: "React, Python",
    },
    {
      img: nf,
      name: "Nature Feerique",
      language: "React, Python",
      link:'https://naturefeerique.com'
    },
    {
      img: laPlan,
      name: "La Plancha website",
      language: "Python, Fluter, React",
    },
  ];
  return (
    <div>
      <ProjectResults
        name={"Python Development"}
        subtext={
          "Python’s simple, easy to learn syntax emphasizes readability and therefore reduces the cost of program maintenance.If you care about building your product quickly without sacrificing the quality, you should be using Python. It’s a versatile, easily readable language, popular with startups and SMEs, great for MVP development and prototyping, and widely used in industries such as fintech, machine learning, or big data.Python has grown to become one of the top languages in the current tech landscape, especially if you’re looking to deliver your product to the market fast or utilize trending solutions.This isn’t just our opinion. Tech giants like Google, YouTube, Instagram, Dropbox, Netflix, or Spotify have all trusted Python, and it clearly was the right choice."
        }
        labelProject={labelProject}
        listProject={listProject}
      />
    </div>
  );
};
export default Python;
