import React, { useEffect, useRef, useState } from "react";

import { FaSortDown } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { RiBarChartHorizontalLine } from "react-icons/ri";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import logoDark from "../../assets/images/logo-dark.png";

const ListOption = [
  {
    name: "About Us",
    url: "/about",
  },
  {
    name: "Services",
    url: "/services",
  },
  {
    name: "Project Timeline",
    url: "/project-timeline",
  },
 
  // {
  //   name: "Case Studies",
  //   url: "/case-studies",
  // },
  // {
  //   name: "Blog",
  //   url: "/blog",
  // },
];

const optionService = [
  {
    name: "Mobile App Development",
    url: "mobile",
  },
  {
    name: "Web App development",
    url: "web",
  },
  {
    name: "Back-end development",
    url: "backend",
  },
  {
    name: "QA & Testing",
    url: "qa",
  },
];

const Header = () => {
  const headerRef = useRef(null);
  const imageHeaderRef = useRef(null);

  // const [isShowSearch, setIsShowSearch] = useState(false);
  const [isShowNavbar, setIsShowNavbar] = useState(false);
  const [isShowSubNavService, setIsShowSubNavService] = useState(false);

  const { pathname } = useLocation();

  const route = useNavigate();

  const handleRoute = (url) => {
    route(url);
    if (isShowNavbar) setIsShowNavbar(false);
    if (document.body.scrollHeight > window.innerHeight) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (
        document.body.scrollTop > 80 ||
        document.documentElement.scrollTop > 80
      ) {
        headerRef.current && headerRef.current.classList.add("scroll");
        if (imageHeaderRef.current?.src) imageHeaderRef.current.src = logoDark;
      } else {
        if (imageHeaderRef.current?.src)
          imageHeaderRef.current.src = "/brand.webp";
        headerRef.current && headerRef.current.classList.remove("scroll");
      }
    });
    return () => {
      window.removeEventListener("scroll", null);
    };
  }, []);

  return (
    <div
      className="fixed top-0 w-full bg-transparent header"
      ref={headerRef}
      style={{
        zIndex: 9999,
      }}
    >
      <div
        className="max-w-[1440px]  w-full px-5 mx-auto header-animation"
        style={{
          backgroundColor: isShowNavbar && "#011434",
        }}
      >
        <div className="flex items-center min-h-[100px] justify-between">
          <Link to="/">
            <img
              className="h-[100px] object-cover w-[190px]"
              alt="name"
              src={"/brand.webp"}
              ref={imageHeaderRef}
            />
          </Link>
          <div className="flex items-center justify-between w-full lg:hidden">
            <div className="flex items-center gap-10 mx-auto ">
              {ListOption.map((item, index) => (
                <div
                  key={index}
                  className={twMerge(
                    "text-lg text-white cursor-pointer transition-all min-h-[100px] hover:text-primary duration-400 ease-in-out header-text-color py-9 font-normal",
                    item.name === "Services" && "group",
                  )}
                  style={{
                    color: pathname.includes(item.url) && "#00b4ff",
                  }}
                >
                  {item.name === "Services" ? (
                    <div className="relative flex ">
                      {item.name}{" "}
                      <FaSortDown className="text-[15px] mt-[3px]" />
                      <div
                        className={
                          "absolute transition z-10 bg-white shadow-md text-black py-4 rounded-lg top-[54px] duration-500 opacity-0 pointer-events-none ease-in-out group-hover:opacity-100 group-hover:pointer-events-auto "
                        }
                      >
                        <div className="flex flex-col gap-2 px-4">
                          {optionService.map((subItem, subIndex) => (
                            <div
                              key={subIndex}
                              onClick={() => handleRoute(subItem.url)}
                              style={{
                                color:
                                  pathname.includes(subItem.url) && "#00b4ff",
                              }}
                              className="whitespace-nowrap cursor-pointer hover:text-[#00b4ff] pb-2 transition duration-300 ease-in-out "
                            >
                              {subItem.name}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="cursor-pointer"
                      onClick={() => handleRoute(item.url)}
                    >
                      {item.name}
                    </div>
                  )}
                </div>
              ))}
              <Link
                to="/get-started"
                className="text-white bg-gradion cursor-pointer h-10 w-[150px] flex items-center justify-center rounded-lg"
              >
                Request Quote
              </Link>
              <a
                href={"https://wa.me/+9710543959830"}
                onClick={() =>
                  window.gtag("event", "whatsapp", {
                    event_category: "contact",
                    event_label: "whatsapp",
                    send_to: "AW-16681535148/vSb2CL2_os0ZEKyNsJI-",
                  })
                }
                className={
                  "border-blue-400 p-2 bg-transparent shadow-md text-blue-600 drop-shadow-2xl border-2 rounded-lg"
                }
              >
                +971 05 439 598 30
              </a>
            </div>
          </div>
          <div className="hidden lg:block">
            <RiBarChartHorizontalLine
              onClick={() => setIsShowNavbar(!isShowNavbar)}
              className="text-[26px] cursor-pointer header-icon text-white transition duration-400 ease-in-out"
            />
            <div
              className={twMerge(
                "fixed inset-0 py-10 pt-0 opacity-0 text-white flex flex-col gap-10 text-center pointer-events-none transition-all duration-300 ease-in-out bg-secondary ",
                isShowNavbar && "opacity-100 pointer-events-auto",
              )}
            >
              <div className="flex justify-between px-5">
                <img
                  className="h-[100px] w-[190px] object-cover"
                  alt="name"
                  src={"/brand.webp"}
                />
                <div className="self-center">
                  <IoMdClose
                    className="text-[32px] cursor-pointer text-white"
                    onClick={() => setIsShowNavbar(false)}
                  />
                </div>
              </div>
              {ListOption.map((item, index) => (
                <div
                  className="text-white text-[18px] transition-all duration-400 font-normal hover:text-primary "
                  style={{
                    color: pathname.includes(item.url) ? "#00b4ff" : "",
                  }}
                  key={index}
                >
                  {item.name === "Services" ? (
                    <div className="flex flex-col justify-center ">
                      <div
                        className="flex justify-center cursor-pointer"
                        onClick={() =>
                          setIsShowSubNavService(!isShowSubNavService)
                        }
                      >
                        {item.name} <FaSortDown className="text-[15px]" />
                      </div>

                      <div
                        className={twMerge(
                          "transition z-10 h-0 overflow-hidden text-white rounded-lg duration-500 ease-in-out ",
                          isShowSubNavService && "h-fit",
                        )}
                      >
                        <div className="flex flex-col py-4 bg-[#111a35] my-4 gap-2 px-4">
                          {optionService.map((subItem, subIndex) => (
                            <div
                              key={subIndex}
                              onClick={() => handleRoute(subItem.url)}
                              style={{
                                color:
                                  pathname.includes(subItem.url) && "#00b4ff",
                              }}
                              className="whitespace-nowrap cursor-pointer text-base hover:text-[#00b4ff] pb-2 transition duration-300 ease-in-out "
                            >
                              {subItem.name}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="cursor-pointer"
                      onClick={() => handleRoute(item.url)}
                    >
                      {item.name}
                    </div>
                  )}
                </div>
              ))}
              <div
                onClick={() => handleRoute("/get-started")}
                className="text-white bg-gradion mx-auto cursor-pointer h-10 w-[150px] flex items-center justify-center rounded-lg"
              >
                Request Quote
              </div>
              <a
                href={"https://wa.me/+9710543959830"}
                onClick={() =>
                  window.gtag("event", "whatsapp", {
                    event_category: "contact",
                    event_label: "whatsapp",
                    send_to: "AW-16681535148/vSb2CL2_os0ZEKyNsJI-",
                  })
                }
                className={
                  "ml-4 border-blue-400 p-3 bg-transparent shadow-md text-blue-600 drop-shadow-2xl border-2 rounded-lg w-[350px]"
                }
              >
                +971 05 439 598 30
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
