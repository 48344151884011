import React from "react";
import toast from "react-hot-toast";
const appScript =
  "https://script.google.com/macros/s/AKfycbzKKLsgSImmyyH307PRgL4Vl-Nmty00E25i9Is5UT4ew63gAPKLPg-k_4CH8jl_5j1j5A/exec";
const languages = ["English", "French"];
const optionCheck = [
  {
    label: "What services are you interested in?",
    checks: [
      {
        label: "Web App",
        value: false,
        name: "web-app",
      },
      {
        label: "Backend development",
        value: false,
        name: "backend",
      },
      {
        label: "Design",
        value: false,
        name: "design",
      },
      {
        label: "QA & testing",
        value: false,
        name: "qa-testing",
      },
      {
        label: "Other",
        value: false,
        name: "other",
      },
    ],
  },
  {
    label: "Project development Stage",
    checks: [
      {
        label: "New - to be built from scratch",
        value: false,
        name: "New - to be built from scratch",
      },
      {
        label: "Existing - to continue development",
        value: false,
        name: "Existing - to continue development",
      },
    ],
  },
  {
    label: "Start date",
    checks: [
      {
        label: "ASAP",
        value: false,
        name: "asap",
      },
      {
        label: "In a few weeks",
        value: false,
        name: "few-week",
      },
      {
        label: "I am not sure",
        value: false,
        name: "not-sure",
      },
    ],
  },
];

const ContactForm = () => {
  const [loading, setLoading] = React.useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData(e.currentTarget);
    const service = formData.getAll("Service");
    const listData = Object.fromEntries(formData.entries());
    const lastFormData = {
      ...listData,
      Service: service.join(",").toString(),
    };

    return await fetch(appScript, {
      method: "POST",
      body: JSON.stringify(lastFormData),
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success("We have receive you requested!");
          window.gtag("event", "conversion", {
            send_to: "AW-16681535148/vSb2CL2_os0ZEKyNsJI-",
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <form
      className=" h-fit bg-white w-[80%] -mt-48 lg:mt-14 mb-14 mx-auto p-14 md:p-8 min-sm:p-[30px]"
      onSubmit={handleSubmit}
      style={{
        boxShadow: "0 4px 99px rgba(0,0,0,.25)",
        borderRadius: "20px",
      }}
    >
      <h4 className="text-[30px] font-bold min-sm:text-[16px] ">
        {"// Who are you"}
      </h4>
      <h2 className="font-bold pb-[10px] text-[50px] mb-[30px] min-sm:text-[34px]">
        Your information
      </h2>
      <div className="grid grid-cols-4 gap-10 mb-10 md:gap-5 min-lg:grid-cols-2 md:grid-cols-1">
        <div className="flex flex-col">
          <p
            className=" text-[#00b4ff] leading-6 mb-1"
            style={{ fontSize: 16 }}
          >
            Name*
          </p>
          <input
            type="text"
            className="w-full h-[50px] leading-6 text-[18px] text-[#666]"
            style={{
              boxShadow: "4px 10px 20px rgba(0,0,0,.1)",
              borderRadius: "10px",
              border: "1px solid #e5e5e5",
              textIndent: "10px",
            }}
            name="Name"
            placeholder="Type here..."
          />
        </div>
        <div className="flex flex-col ">
          <p
            className=" text-[#00b4ff] leading-6 mb-1"
            style={{ fontSize: 16 }}
          >
            Email*
          </p>
          <input
            type="email"
            className="w-full h-[50px] leading-6 text-[18px] text-[#666]"
            style={{
              boxShadow: "4px 10px 20px rgba(0,0,0,.1)",
              borderRadius: "10px",
              border: "1px solid #e5e5e5",
              textIndent: "10px",
            }}
            placeholder="Type here..."
            name="Email"
          />
        </div>
        <div className="flex flex-col ">
          <p
            className=" text-[#00b4ff] leading-6 mb-1 whitespace-nowrap"
            style={{ fontSize: 16 }}
          >
            Preferred Language*
          </p>
          <select
            className="w-full h-[50px] leading-6 text-[18px] text-[#666]"
            style={{
              boxShadow: "4px 10px 20px rgba(0,0,0,.1)",
              borderRadius: "10px",
              border: "1px solid #e5e5e5",
              textIndent: "10px",
            }}
            name="Language"
          >
            {languages.map((language) => (
              <option key={language} value={language}>
                {language}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col">
          <p className="mb-1 text-base leading-6 whitespace-nowrap ">
            Phone (if possible)
          </p>
          <input
            type="text"
            className="w-full h-[50px] leading-6 text-[18px] text-[#666]"
            style={{
              boxShadow: "4px 10px 20px rgba(0,0,0,.1)",
              borderRadius: "10px",
              border: "1px solid #e5e5e5",
              textIndent: "10px",
            }}
            placeholder="Type here..."
            name="Phone"
          />
        </div>
      </div>
      <h4 className="text-[30px] font-bold min-sm:text-[16px] ">{"// What"}</h4>
      <h2 className="font-bold pb-[10px] text-[50px] mb-[30px] min-sm:text-[34px]">
        Project Description
      </h2>
      <p className=" text-[#00b4ff] leading-6 mb-1" style={{ fontSize: 16 }}>
        Description
      </p>
      <textarea
        className="w-full leading-6 text-[18px] border-[#e5e5e5] border text-[#666] hover:border-[#00b4ff] focus:border-[#00b4ff]"
        style={{
          boxShadow: "4px 10px 20px rgba(0,0,0,.1)",
          borderRadius: "10px",
          textIndent: "10px",
        }}
        placeholder="Type here..."
        rows={7}
        name="Project Description"
      />
      <div className="flex flex-row justify-center gap-32 mx-auto mt-6 md:flex-col md:gap-0">
        <div className="flex flex-col min-sm:w-full ">
          <p className=" text-[#00b4ff] leading-6 font-bold mb-[18px]  text-[16px] min-sm:text-[12px]">
            {optionCheck[0].label}
          </p>
          {optionCheck[0]?.checks.map((item, index) => {
            return (
              <div key={index} className="flex flex-row gap-2 mb-4">
                <input
                  type="checkbox"
                  className="border border-[#e5e5e5] bg-white mt-[2px]"
                  style={{
                    boxShadow: "0 0 10px rgba(0,0,0,.15)",
                    borderRadius: "5px",
                    height: 20,
                    width: 20,
                  }}
                  name={"Service"}
                  id={item.name}
                  value={item.name}
                />
                <label
                  htmlFor={item.name}
                  className=" text-[#141d38] cursor-pointer font-normal leading-6"
                  style={{ fontSize: "16px" }}
                >
                  {item.label}
                </label>
              </div>
            );
          })}
        </div>
        <div className="flex flex-col ">
          <p className=" text-[#00b4ff] leading-6 font-bold mb-[18px] text-[16px] min-sm:text-[12px]">
            {optionCheck[1].label}
          </p>
          {optionCheck[1]?.checks.map((item, index) => {
            return (
              <div key={index} className="flex flex-row gap-2 mb-4">
                <input
                  type="radio"
                  className="border border-[#e5e5e5] bg-white mt-[2px]"
                  style={{
                    boxShadow: "0 0 10px rgba(0,0,0,.15)",
                    borderRadius: "5px",
                    height: 20,
                    width: 20,
                  }}
                  name={"Project Stage"}
                  id={item.name}
                  value={item.name}
                />
                <label
                  htmlFor={item.name}
                  className=" text-[#141d38] cursor-pointer font-normal leading-6"
                  style={{ fontSize: "16px" }}
                >
                  {item.label}
                </label>
              </div>
            );
          })}
          <p className=" text-[#00b4ff] leading-6 font-bold mb-[18px] mt-5 md:mt-2 md:text-xs">
            {optionCheck[2].label}
          </p>
          {optionCheck[2]?.checks.map((item, index) => {
            return (
              <div key={index} className="flex flex-row gap-2 mb-4">
                <input
                  type="radio"
                  className="border border-[#e5e5e5] bg-white mt-[2px] rounded-[5px]"
                  style={{
                    boxShadow: "0 0 10px rgba(0,0,0,.15)",
                    height: 20,
                    width: 20,
                  }}
                  name={"Project Timeline"}
                  id={item.name}
                  value={item.name}
                />
                <label
                  htmlFor={item.name}
                  className=" text-[#141d38] cursor-pointer font-normal leading-6"
                  style={{ fontSize: "16px" }}
                >
                  {item.label}
                </label>
              </div>
            );
          })}
        </div>
      </div>

      <div className="flex justify-center w-full">
        <button
          disabled={loading}
          type="submit"
          className="rounded-[8px] w-[324px] h-auto font-bold border-0 mt-16  text-white py-4 px-10  text-[22px] bgi-gradion min-sm:px-4  "
        >
          SEND REQUEST
        </button>
      </div>
    </form>
  );
};

export default ContactForm;
