import React from "react";

const ProjectContentItem = ({ item }) => {
  return (
    <div className="flex gap-[12%] md:gap-8 sm:gap-5 items-center md:flex-col">
      <div className="w-[55%] md:w-full">
        <h4 className="text-[30px] font-bold  md:text-xl"> {item.title}</h4>
        <h2 className="text-[40px] font-bold py-5 pt-2 md:text-3xl">
          {item?.name}
        </h2>

        <div className="text-[#7e8f9a] text-[18px]">
          <div>{item.description}</div>

          {item?.subContent &&
            item?.subContent.map((subItem, subIndex) => (
              <div key={subIndex}>
                <div className="py-5 pb-2 font-bold text-black">
                  {subItem?.title}
                </div>
                <ul className={subItem?.content?.length > 1 ? "px-10" : ""}>
                  {subItem?.content?.map((contentItem, contentIndex) => (
                    <li
                      className={
                        subItem?.content?.length > 1 ? "list-disc" : ""
                      }
                      key={contentIndex}
                    >
                      {contentItem}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          {item?.bonus && item.bonus}
        </div>
      </div>
      <div className="w-[35%] h-full md:w-full">
        <img
          src={item?.img}
          alt="content-1"
          className="h-full ml-auto md:m-auto"
        />
      </div>
    </div>
  );
};

export default ProjectContentItem;
