import React from "react";
import { BsWhatsapp } from "react-icons/bs";
import { CiLocationOn } from "react-icons/ci";
import { listOptionFooter } from "../../utils/mock-data";
import { Link } from "react-router-dom";

const options = [
  {
    name: "About Us",
    url: "/about",
  },
  {
    name: "Services",
    url: "/mobile",
  },
  {
    name: "Project Timeline",
    url: "/project-timeline",
  },
  {
    name: "Insights",
    url: "/",
  },
  {
    name: "Contact",
    url: "/get-started",
  },
];

const Footer = () => {
  return (
    <div className="bg-[#011434]  py-10 text-[17.5px]">
      <div className="max-w-[1440px] w-full px-10 mx-auto flex flex-col gap-16 sm:px-5">
        <div className="flex flex-wrap justify-between gap-10">
          {listOptionFooter.map((item, index) => (
            <div key={index} className="flex flex-col gap-2.5">
              <div className="text-[#00b4ff] text-xl font-semibold">
                {item.tile}
              </div>
              <div className="text-white flex flex-col gap-2.5">
                {item.result.map((subItem) => (
                  <Link
                    to={subItem.url}
                    key={subItem.name}
                    className="cursor-pointer hover:text-[#00b4ff] transition duration-300 ease-in-out"
                  >
                    {subItem.name}
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="mx-auto text-center">
          <div id="contact">
            <img src="/brand.webp" alt="banner" className="h-32 mx-auto mb-8" />
            <div className="flex flex-wrap justify-center gap-4">
              <div className="flex items-center gap-2 text-white whitespace-nowrap">
                <CiLocationOn className="text-[#00b4ff]" />
                Dubai, UAE
              </div>
              <div className="flex items-center gap-2 text-white whitespace-nowrap">
                <CiLocationOn className="text-[#00b4ff]" />
                Paris, France
              </div>
              <div className="flex items-center gap-2 text-white whitespace-nowrap">
                <CiLocationOn className="text-[#00b4ff]" />
                Ha Noi, Viet Nam
              </div>
              <a
                className="flex items-center gap-2 text-white whitespace-nowrap"
                href={"https://wa.me/+9710543959830"}
              >
                <BsWhatsapp className="text-[#00b4ff]" />
                Whatsapp
              </a>
            </div>
          </div>
        </div>
        <div className="flex justify-between gap-10 py-10 md:flex-wrap md:justify-center">
          <div className="flex flex-wrap gap-6 md:hidden ">
            {options.map((item, index) => (
              <Link
                to={item.url}
                key={index}
                className="text-white cursor-pointer hover:text-[#00eeff] duration-300 ease-in-out transition-all"
              >
                {item.name}
              </Link>
            ))}
          </div>
          <div className="flex gap-5 md:flex-col-reverse sm:justify-center">
            <div className="text-[#ffffff8f]">
              © 2022 CFI Dev. All rights reserved.
            </div>
            <div className="flex gap-5 md:justify-center">
              <Link
                to={"/terms"}
                className="text-white cursor-pointer hover:text-[#00eeff] duration-300 ease-in-out transition-all"
              >
                Terms of Service
              </Link>
              <Link
                to={"/privacy"}
                className="text-white cursor-pointer hover:text-[#00eeff] duration-300 ease-in-out transition-all"
              >
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
