import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import PageRender from "./utils/render";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<PageRender />} />
            <Route path="/:page" element={<PageRender />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </div>
  );
}

export default App;
