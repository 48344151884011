import ProjectResults from "../components/ProjectResults";
import React from "react";
import la from '../assets/la_plancha.png'
import smartEdit from '../assets/smartEdit.png'
import light from '../assets/smartlight.png'
import istick from '../assets/isticker.png'
const Fluter = () => {
  const labelProject = "OUR FLUTTER APPS";
  const listProject = [
    {
      img: smartEdit,
      name: "Smart Edit App",
      language: "Flutter, Python",
      link:'https://play.google.com/store/apps/details?id=com.abi.remove.plus.background'
    },
    {
      img: istick,
      name: "ISticker App",
      language: "Flutter",
      link:'https://play.google.com/store/apps/details?id=com.isticker.sticker.maker'
    },
    
    {
      img: light,
      name: "SmartLight App",
      language: "Flutter",
    },
    {
      img: la,
      name: "La Planche App",
      language: "Flutter",
    },
    
  ];
  return (
    <div>
      <ProjectResults
        name={"Flutter App Development"}
        subtext={
          "Flutter is a super useful, super versatile open-source framework developed by Google to simplify app development. Using a single set of source code, engineers can develop apps that integrate with many different platforms. Flutter can be the perfect choice for those looking to develop a multi-platform app without the time, labor, and cost of developing all-native code. We love Flutter and our customers do, too."
        }
        labelProject={labelProject}
        listProject={listProject}
      />
    </div>
  );
};
export default Fluter;
