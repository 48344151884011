import React from "react";
import Banner from "../assets/images/banner-iot-service.jpg";
import image1 from "../assets/images/timeline-1.jpg";
import image2 from "../assets/images/timeline-2.jpg";
import image3 from "../assets/images/timeline-3.jpg";
import image4 from "../assets/images/timeline-4.jpg";
import image5 from "../assets/images/timeline-5.jpg";
import image6 from "../assets/images/timeline-6.jpg";
import image7 from "../assets/images/timeline-7.jpg";
import ProjectContentItem from "../components/project-content-item/ProjectContentItem";
import { Link } from "react-router-dom";

const listContentTimeline = [
  {
    title: "// Your project",
    name: "1. First Contact: Send Us Project Specifications",
    description:
      "To get started, we’ll need to know what you’re looking for — including goals for this particular project as well as your overall business goals. Understanding your company’s overall direction helps us better understand just what you need and how we can help you get where you need to go.",
    subContent: [
      {
        title: "Information that can help us includes:",
        content: [
          "The platform you need: mobile, web, desktop, etc.",
          " Which system you need: Android, iOS, Windows, etc.",
          "Which programming languages you’d like: Vue.js, React Native, Swift, etc. If you don’t know, that’s ok — we’ll guide you through the process.",
          "The design specifications of the project: wireframes, final design, MVP, etc.",
          "Your timeframe and/or deadline",
          "Any additional info you think would help us",
        ],
      },
    ],
    bonus:
      "Once we have this information, we’ll begin a dialogue with you that will get us started.",
    img: image1,
  },
  {
    title: "// Love at first sight",
    name: "2. Meet Our Team: Free Consultation",
    description: (
      <div className="flex flex-col gap-4">
        <div>
          Once we’ve established initial contact, our team will reach out and
          schedule a meeting with you. In this session, you’ll meet our sales
          and technical team lead, who will guide you through our development
          process and answer any questions you may have about our company. We’ll
          discuss details of your project, including timeline and will clarify
          any questions you may have.
        </div>
        <div>
          This meeting is free of charge — so don’t hesitate to reach out to us.
        </div>
        <div>
          If you like what you see and want to get started, we’ll sign a
          non-disclosure agreement (NDA) to protect you and your intellectual
          property.
        </div>
      </div>
    ),
    img: image2,
  },
  {
    title: "// Ready",
    name: "3. Proposal and Review",
    description: (
      <div className="flex flex-col gap-4">
        <div>
          Once we’ve met you and discussed your needs, we’ll get back to you
          within 48 hours with a project proposal. Note that this proposal does
          not involve us getting on one knee with a bouquet of flowers and a
          ring. This proposal does, however, include a full plan, like a
          detailed timeline, team members and their daily rates, and any
          relevant project milestones to keep track of.
        </div>
        <div>
          Of course, you’ll have the chance to review the proposal and ask any
          questions or request changes. Once you agree with the proposal, we’ll
          sign a contract and get to work.
        </div>
      </div>
    ),
    img: image3,
  },
  {
    title: "// Set",
    name: "4. Story Mapping",
    description: (
      <div className="flex flex-col gap-4">
        <div>
          The story map gives a detailed, bird’s-eye overview of your project to
          help visualize and understand it. It’ll give you an idea of how all
          the cogs and gears work together to make our well-oiled machine tick
          and show you how everything is covered.
        </div>
        <div>
          Once the story map is completed, it may be easier for you than us to
          write your project story since you should have a full understanding of
          it. This kind of writing isn’t an easy task for everyone, however — so
          if this isn’t your cup of tea you can delegate this or set it aside.
        </div>
      </div>
    ),
    img: image4,
  },
  {
    title: "// Go",
    name: "5. Workflow: From Sprint 0 to the Final Sprint",
    subContent: [
      {
        title: "After Sprint 0, we will deliver:",
        content: [
          "A functional piece of code, however small",
          "A feature prioritization plan or a list of project stories",
          "A release plan, which assigns each story to a Sprint",
        ],
      },
    ],
    description:
      "Our projects work in “Sprints,” components of work dedicated to particular tasks or series of tasks. Sprint 0, for example, results in a comprehensive project plan.",
    bonus:
      "At the end of each Sprint, we’ll show you what we’ve accomplished by showing you the partially complete version of your project. We’re all about accountability and transparency, and you deserve to know just what’s happening.",
    img: image5,
  },
  {
    title: "// Deploy",
    name: "6. Publication",
    description:
      "After passing the User Acceptance Test (UAT), the product will be available to end users and will be deployed in a production environment. In other words, it’s done and ready to go.",
    img: image6,
  },
  {
    title: "// Maintain",
    name: "7. Maintenance and Improvements",
    description:
      "We’ll be with you after the project is done to conduct reviews, provide maintenance advice, or deliver upgrades. Products in any industry need some review after they hit the market, so we’re there to help you through the “unknown unknowns” — the unanticipated results that often accompany a new product.",
    img: image7,
  },
];

const ProjectTimeline = () => {
  return (
    <div>
      <div
        className="object-cover w-full min-h-[610px] h-full pt-20 pb-10 lg:min-h-full"
        style={{
          backgroundImage: `url(${Banner})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="py-10 md:py-24 px-10 max-w-[1440px] mx-auto">
          <div className="flex flex-col gap-8 text-white ">
            <h2 className="text-[60px] md:text-[32px] font-bold md:text-center">
              PROJECT TIMELINE
            </h2>
            <span className="max-w-[70%] text-[18px] md:hidden">
              When you’re looking for a developer, knowing where to start is
              often the hardest part. But hey, lucky you! You’re already here,
              so you’ve already cleared that hurdle. We’re an experienced,
              flexible, and talented group of developers, and we would love to
              be just what you’re looking for. We’ve worked with projects in all
              shapes and sizes, from small projects for start-ups to large
              campaigns for major corporations, so we know how to tailor our
              work to your needs.
            </span>
            <div className="flex gap-10 text-[22px] md:hidden">
              <Link
                to="/get-started"
                className="cursor-pointer h-[67px] font-bold hover:bg-[#00eeff] hover:border-[#00eeff] whitespace-nowrap border border-white rounded-lg flex justify-center items-center transition duration-300 ease-in-out w-[268px] "
              >
                Get a Free Quote
              </Link>
              <Link
                to="/get-started"
                className="cursor-pointer bg-gradion h-[67px] font-bold hover:bg-[#00eeff] whitespace-nowrap  rounded-lg flex justify-center items-center transition duration-300 ease-in-out w-[268px] "
              >
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-[1440px] flex flex-col gap-32 md:gap-20 sm:gap-10 py-20 md:py-16 mx-auto xl:w-full px-10">
        {listContentTimeline.map((item, index) => (
          <ProjectContentItem item={item} key={index} index={index} />
        ))}
      </div>
    </div>
  );
};

export default ProjectTimeline;
