import React from "react";

const AgileTeams = () => {
  return (
    <div
      className="p-8 "
      style={{
        boxShadow: "0 .375em 2.8125em 0 #d2d5d9",
      }}
    >
      <div className="mb-5 text-[21px] text-black">Agile Teams</div>

      <div>
        <h2 className="pb-2 text-xl font-bold text-black">
          How Do You Build and Manage a High-Performance Agile Team?
        </h2>
        <div className="flex flex-col gap-4">
          <span>
            This is perhaps one of the most vital questions in all areas of
            human endeavours, from sports to software and everything in between.
            Humans are an aggressively social species because we need each other
            to accomplish complex tasks. When a team performs well together, the
            result is far greater than the sum of its parts. We’ve identified a
            few key elements that can help a team reach its highest potential.
          </span>
          <span>
            Ensuring team members have complementary skills is one of the most
            vital steps management can take. When all necessary skills for a
            complex task are covered, work can be delegated, and individuals can
            focus on what they do best. As Einstein said, “Everybody is a
            genius. But if you judge a fish by its ability to climb a tree,
            it’ll spend its entire life believing it’s stupid.” Identifying and
            encouraging individuals’ skills helps workers do their job well and
            have a sense of pride in doing so.
          </span>
          <span>
            Next, having well-defined objectives — within which workers can work
            to the maximum possible degree of autonomy — is also a recipe for
            success. If nobody knows where the goal post is, how can they know
            how to score? Each team member needs to know what success looks like
            and how they can contribute to it.
          </span>
          <span>
            Additionally, while company culture is often discussed like an
            inanimate object in the business world, it’s not something that just
            happens. Company culture is often a result of both inorganic and
            organic processes, i.e., top-down decisions and structure and
            bottom-up individual personality manifesting throughout an
            organization.
          </span>
          <span>
            A healthy company and team culture is one that fosters trust,
            individual responsibility and accountability, and team members’
            desire to learn and expand both individual and team skills. In many
            cases, a little dash of friendly competition can help push people to
            excel. This all has to take place in a framework that encourages
            openness and honesty, kindness, and shared victories.
          </span>
        </div>
      </div>
    </div>
  );
};

export default AgileTeams;
