import React from "react";
import ShareLayout from "../components/Layout/ShareLayout";
import boss from "../assets/cyril.jpg";
import compass from "../assets/compass.png";
import intro from "../assets/intro1.png";
const About = () => {
  return (
    <ShareLayout
      headerImage={
        "https://smartdev.com/wp-content/uploads/2022/05/banner-about.jpg"
      }
      headerText={"About us"}
    >
      <section style={{ marginTop: "3%" }}>
        <div className={"flex justify-between items-center md:flex-wrap gap-6"}>
          <div className={" w-1/2 md:w-full  pl-5"}>
            <div className={"text-center"}>
              <h1
                className={
                  "text-3xl underline decoration-blue-500 decoration-4"
                }
              >
                Story
              </h1>
            </div>
            <div className={"sm:pr-5"}>
              <p
                className={
                  "whitespace-pre text-xl  text-center mb-4  font-bold md:hidden"
                }
              >
                CFI Tech is a group built with the experience of CFI created in
                1984.
              </p>
              <p className={"text-xl text-gray-600 mb-6"}>
                In 1984, CFI began in the IT and training sectors. In 2017,
                Cyril Osmani expanded the structure created by his father and
                developed the CFI Tech group, a telecommunications specialist.
                The world of telecommunications is then dominated by large
                structures which, often because of their size, lose the human
                side of business, and are confronted with the weight of numerous
                validation procedures before decision-making, which can have a
                strong impact on their responsiveness. With a more human vision
                and innovative strategies, CFI Tech in just a few years
                experienced dazzling growth, and established itself as a young
                professional company, which has nothing to envy to the largest
                companies in the business. CFI Tech today is:
              </p>
              <ul className={"text-xl mb-4 capitalize"}>
                <li>
                  <b>Over 150 Employees</b>
                </li>
                <li>
                  <b>Over 280 Collaborators And Partners</b>
                </li>
              </ul>
              <p className={"text-xl mb-3"}>
                By applying a constant policy of development and innovation, CFI
                Tech deploys its know-how and intervenes in other cutting-edge
                fields such as:
              </p>
              <ul className={"list-disc ml-4 capitalize"}>
                <li>
                  <b>Services Center</b>
                </li>
                <li>
                  <b>Cartography</b>
                </li>
                <li>
                  <b>Innovation & Digital.</b>
                </li>
              </ul>
            </div>
          </div>
          <div className={"md:w-full w-1/2"}>
            <img src={boss} alt={"boss"} className={"w-full h-full"} />
          </div>
        </div>
        <div className={"share_bg flex md:flex-wrap w-full"}>
          <div className={"md:w-full w-1/2"}>
            <img
              src={compass}
              alt={"compass"}
              width={0}
              height={0}
              sizes="100vw"
              style={{ width: "100%", height: "auto" }}
            />
          </div>
          <div className={"md:w-full mt-4 pl-5 w-1/2"}>
            <div className={"text-center"}>
              <h1
                className={
                  "text-3xl underline decoration-red-600 decoration-4 "
                }
              >
                <strong>Mission</strong>
              </h1>
            </div>
            <p className={"text-lg  mt-5"}>
              Our mission is to offer our customers services at very competitive
              prices while maintaining the standard and professionalism of the
              largest companies in the sector. We look to the future and
              challenge the status quo. We draw our strength from our ingenuity,
              and our ability to adapt to the culture of our customers and their
              needs. We rely on a multidisciplinary team and partners, with real
              knowledge of the business to undertake the most complex projects
              in order to always achieve our objectives and provide total
              satisfaction. Our success comes from our ability to remain agile
              and our pragmatic and innovative approach, while keeping our
              business model simple and efficient. We pay particular attention
              to development, growth, and our geographic reach with the ultimate
              goal of better meeting the needs of our customers.{" "}
            </p>
          </div>
        </div>
        <div className={"flex md:flex-wrap items-center"}>
          <div className={"md:w-full px-6 w-1/2"}>
            <div className={"text-center mb-6"}>
              <h1 className={"text-2xl relative about_section_title "}>
                <strong>What inspires us</strong>
              </h1>
            </div>
            <p>
              We deeply believe that development goes through technology and its
              accessibility to all. This means that we constantly seek to step
              out of our comfort zone to have a forward-thinking vision in order
              to provide innovative solutions to the challenges that the future
              will present. This inspires us, pushes us to surpass ourselves and
              to act for the improvement of our environment.
            </p>
          </div>
          <div className={"md:w-full w-1/2"}>
            <img
              src={intro}
              alt={"intro"}
              style={{ width: "100%", height: "550px" }}
            />
          </div>
        </div>
      </section>
    </ShareLayout>
  );
};

export default About;
